import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  CreatePoslovnaJedinicaCommand,
  IMagacinDto,
  IPoslovnaJedinicaDto,
  PoslovnaJedinicaDto,
  PoslovneJediniceClient,
  UpdatePoslovnaJedinicaCommand,
} from '@kodit/core/data-api';
import {
  FormHelper,
  MagacinFormService,
  PoslovnaJedinicaFormService,
} from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-poslovna-jedinica-form',
  templateUrl: './poslovna-jedinica-form.component.html',
  styleUrls: ['./poslovna-jedinica-form.component.scss'],
})
export class PoslovnaJedinicaFormComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _getSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IPoslovnaJedinicaDto>;
  mode: Mode = Mode.NEW;

  constructor(
    private _client: PoslovneJediniceClient,
    private _alertService: AlertService,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _poslovnaJedinicaFS: PoslovnaJedinicaFormService,
    private _magacinFS: MagacinFormService,
    private _FormHelper: FormHelper
  ) {}

  /** OnInit */
  ngOnInit(): void {
    this.form = this._poslovnaJedinicaFS.GetPoslovnaJedinicaFormGroup(
      this._dialogConfig.data.id
    );

    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });

    // get mode and patch if edit
    if (this._dialogConfig.data && this._dialogConfig.data.isEdit) {
      this.getById(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }
  }

  save(): void {
    console.log(this.form);

    if (!this.form.valid) {
      this._FormHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._saveSub = this._client
        .update(
          this.form.value.id,
          this.form.value as UpdatePoslovnaJedinicaCommand
        )
        .subscribe(
          (result) => {
            this._alertService.addSuccessMsg(
              `Poslovna jedinica ${this.form.value.naziv} je uspešno izmenjena.`
            );

            this._dialogRef.close(result);
          },
          (error) => {
            this._alertService.addFailedMsg(error);
          }
        );
    } else {
      this._saveSub = this._client
        .create(this.form.value as CreatePoslovnaJedinicaCommand)
        .subscribe(
          (result) => {
            this._alertService.addSuccessMsg(result.messages[0]);
            this._dialogRef.close(result);
          },
          (error) => {
            this._alertService.addFailedMsg(error);
          }
        );
    }
  }

  getById(id: number): void {
    this._getSub = this._client.getById(id).subscribe(
      (model) => {
        this.patchFormByModel(model);
        this.form.get('adresa').patchValue(model.adresa);
        this.patchMagaciniFormArray(model.magacini);
      },
      (error) => {
        this._alertService.addFailedMsg(error);
      }
    );
  }
  //#endregion

  selectedMagacini(magacini: IMagacinDto[]) {
    this.patchMagaciniFormArray(magacini);
  }

  private patchFormByModel(model: PoslovnaJedinicaDto) {
    this.form.patchValue(model);
  }

  private patchMagaciniFormArray(magacini: IMagacinDto[]) {
    (this.form.controls.magacini as FormArray).clear();
    magacini.forEach((x) => {
      (this.form.controls.magacini as FormArray).push(
        this._magacinFS.GetMagacinFormGroup(x)
      );
    });
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }
  /** OnDestroy */
  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }
}
