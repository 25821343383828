import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Injectable} from '@angular/core';
import {
  IInvoiceItemDto,
  IInvoiceOptionDto,
  IInvoicePaymentDto,
  IInvoiceRequestDto,
  IReferentniFiskalniRacunDto,
  InvoiceType,
  TransactionType,
  IGetFiskalniRacunTableQuery, IJournalOptionDto,
} from '@kodit/core/data-api';
import {FormArray, FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FiskalniRacunFormService {
  constructor(private _fb: FormBuilderTypeSafe) {
  }

  public GetInvoiceRequestFormGroup(
    model?: IInvoiceRequestDto
  ): FormGroupTypeSafe<IInvoiceRequestDto> {
    return this._fb.group<IInvoiceRequestDto>({
      invoiceRequestId: new FormControl(model?.invoiceRequestId),
      dateAndTimeOfIssue: new FormControl(model?.dateAndTimeOfIssue),
      cashier: new FormControl(model?.cashier),
      buyerId: new FormControl(model?.buyerId),
      buyerCostCenterId: new FormControl(model?.buyerCostCenterId),
      buyerIdentification: new FormControl(model?.buyerIdentification),
      buyerOptionalField: new FormControl(model?.buyerOptionalField),
      invoiceType: new FormControl(model?.invoiceType ?? InvoiceType.NORMAL),
      transactionType: new FormControl(
        model?.transactionType ?? TransactionType.SALE
      ),
      invoiceNumber: new FormControl(model?.invoiceNumber),
      referentniFiskalniRacunDto: this.GetReferentniFiskalniRacunGroup(
        model?.referentniFiskalniRacunDto
      ),
      journalOption: this.GetJournalOptions(model?.journalOption),
      optionId: new FormControl(model?.optionId),
      option: this.GetInvoiceOptionGroup(model?.option),
      invoiceItems: this.GetInvoiceItemFormArray(model?.invoiceItems),
      invoicePayments: this.GetInvoicePaymentFormArray(model?.invoicePayments),
    });
  }

  //#region Invoice
  public GetInvoiceItemFormArray(invoiceItems?: IInvoiceItemDto[]): FormArray {
    if (!invoiceItems || invoiceItems.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);
    invoiceItems.forEach((s) => {
      result.push(this.GetInvoiceItemFormGroup(s));
    });
    return result;
  }

  public GetInvoicePaymentFormArray(
    invoicePayments?: IInvoicePaymentDto[]
  ): FormArray {
    if (!invoicePayments || invoicePayments.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);
    invoicePayments.forEach((s) => {
      result.push(this.GetInvoicePaymentGroup(s));
    });
    return result;
  }

  public GetInvoiceItemFormGroup(
    model?: IInvoiceItemDto
  ): FormGroupTypeSafe<IInvoiceItemDto> {
    return this._fb.group<IInvoiceItemDto>({
      invoiceItemId: new FormControl(model?.invoiceItemId),
      gtin: new FormControl(model?.gtin),
      name: new FormControl(model?.name),
      quantity: new FormControl(model?.quantity ?? 1),
      magacinId: new FormControl(model?.magacinId),
      totalAmount: new FormControl(model?.totalAmount ?? 0),
      unitPrice: new FormControl(model?.unitPrice ?? 0),
      labels: new FormControl(model?.labels),
      predmetStavkeId: new FormControl(model?.predmetStavkeId),
    });
  }

  public GetInvoicePaymentGroup(
    model?: IInvoicePaymentDto
  ): FormGroupTypeSafe<IInvoicePaymentDto> {
    return this._fb.group<IInvoicePaymentDto>({
      amount: new FormControl(model?.amount),
      paymentType: new FormControl(model?.paymentType),
    });
  }

  public GetInvoiceOptionGroup(
    model?: IInvoiceOptionDto
  ): FormGroupTypeSafe<IInvoiceOptionDto> {
    return this._fb.group<IInvoiceOptionDto>({
      omitQRCodeGen: new FormControl(model?.omitQRCodeGen ?? 0),
      omitTextualRepresentation: new FormControl(
        model?.omitTextualRepresentation ?? 0
      ),
    });
  }

  public GetReferentniFiskalniRacunGroup(
    model?: IReferentniFiskalniRacunDto
  ): FormGroupTypeSafe<IReferentniFiskalniRacunDto> {
    return this._fb.group<IReferentniFiskalniRacunDto>({
      id: new FormControl(model?.id),
      referentDocumentDT: new FormControl(model?.referentDocumentDT),
      referentDocumentNumber: new FormControl(model?.referentDocumentNumber),
      jeStariRacun: new FormControl(model?.jeStariRacun),
      hasStariReferentniRacun: new FormControl(
        model?.hasStariReferentniRacun ?? false
      ),
      invoiceType : new FormControl(model?.invoiceType),
      transactionType : new FormControl(model?.transactionType)
    });
  }

  public GetFiskalniRacunTableRequest(
    model?: IGetFiskalniRacunTableQuery
  ): FormGroupTypeSafe<IGetFiskalniRacunTableQuery> {
    return this._fb.group<IGetFiskalniRacunTableQuery>({
      poslovniProstor: new FormControl(model?.poslovniProstor),
      brojRacuna: new FormControl(model?.brojRacuna),
      kasir: new FormControl(model?.kasir),
      status: new FormControl(model?.status),
      vrstaRacuna: new FormControl(model?.vrstaRacuna),
      tipTransakcije: new FormControl(model?.tipTransakcije),
    });
  }

  public GetJournalOptions(
    model?: IJournalOptionDto
  ): FormGroupTypeSafe<IJournalOptionDto> {
    return this._fb.group<IJournalOptionDto>({
      journalOptionId : new FormControl(model?.journalOptionId),
      print : new FormControl(model?.print),
      a4Printer : new FormControl(model?.a4Printer),
      message : new FormControl(model?.message),
      preamble : new FormControl(model?.preamble),
      advance : new FormControl(model?.advance ?? 0),
      advanceTax : new FormControl(model?.advanceTax ?? 0)
    });
  }

  public exactlyNumberOfCharactersValidator(expectedLength: number) {
    return function (control: FormControl) {
      const value = control.value as string;
      if (value?.length !== expectedLength) {
        return {invalidLength: true};
      }
      return null;
    };
  }
}
