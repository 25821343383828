
<div style="  height: 150px; margin-top:2rem; background-color: rgb(0 128 202); border-radius: 20px;">
  <div style="padding: 40px; padding-left: 0px">
    <h2 style="color: white;text-align: center;">Tehnička podrška / Često postavljana pitanja</h2>
    <p style="color: white;text-align: center;">Pronađite brze odgovore na svoja pitanja i iskoristite sve pogodnosti eKompanije.</p>
  </div>
</div>

<div class="p-d-flex p-jc-between" style=" height: 100px; margin-top:2rem">
  <div>
    <h4>Kako možemo da pomognemo?</h4>
    <p>U nastavku smo prikupili neka od naših najčešće postavljanih pitanja i linkova kako bismo vam pomogli da odgovorite na sva pitanja koja imate.</p>
  </div>
</div>


<section class="intro_cards p-grid p-mt-2">
  <div class="intro_cards_container">

    <div class="intro_card_container p-col-12 p-md-6 p-lg-4">
      <div class="card align_center">


        <a data-clog-click="true" data-clog-ui-component="hc_primary_card" data-clog-ui-element="link_getting_started" href="https://ekompanija.tawk.help/" target="_blank" class="tile" title="Getting Started">
          <img class="card_image" border="0" src="https://www.questionpro.com/qp_userimages/sub-3/3342920/Recurso-44x_2.png">
          <h2 class="card_title">Uvod</h2>
          <p class="card_description">Kako početi sa korišćenjem ekompanija aplikacije</p>
        </a>
      </div>
    </div>

    <div class="intro_card_container p-col-12 p-md-6 p-lg-4">
      <div class="card align_center">
        <a data-clog-click="true" data-clog-ui-component="hc_primary_card" data-clog-ui-element="link_using_slack" href="https://ekompanija.tawk.help/article/1" target="_blank" class="tile" title="Using Slack">
          <img class="card_image" border="0" src="https://www.questionpro.com/qp_userimages/sub-3/3342920/Recurso-24x_2.png">
          <h2 class="card_title">Tehnička podrška - kontakt</h2>
          <p class="card_description">Ovde možete pronaći brojeve telefona agenata tehničke podrške.</p>
        </a>
      </div>
    </div>

    <div class="intro_card_container p-col-12 p-md-6 p-lg-4">
      <div class="card align_center ">
        <a data-clog-click="true" data-clog-ui-component="hc_primary_card" data-clog-ui-element="link_profile_preferences" href="https://www.youtube.com/watch?v=KFxEMyfwG7E&list=PLbv3K0V3KQtxhUJ_DlUnmKTUrwIxADwgf" target="_blank" class="tile" title="Your Profile &amp; Preferences">
          <img class="card_image" border="0" src="https://www.questionpro.com/qp_userimages/sub-3/3342920/Recurso-54x_1.png">
          <h2 class="card_title">Video uputstva</h2>
          <p class="card_description">Ovde možete pronaći video uputstva.</p>
        </a>
      </div>
    </div>

    <div class="intro_card_container p-col-12 p-md-6 p-lg-4">
      <div class="card align_center">
        <a data-clog-click="true" data-clog-ui-component="hc_primary_card" data-clog-ui-element="link_team_administration" href="https://ekompanija.tawk.help/article/istorija-izmena" target="_blank" class="tile" title="Workspace Administration">
          <img class="card_image" border="0" src="https://www.questionpro.com/qp_userimages/sub-3/3342920/Recurso-14x_4.png">
          <h2 class="card_title">Trenutna verzija aplikacije</h2>
          <p class="card_description">Ovde možete pronaći informacije o trenutnoj verziji aplikacije, izmenama i dopunama.</p>
        </a>
      </div>
    </div>

    <div class="intro_card_container p-col-12 p-md-6 p-lg-4">
      <div class="card align_center">
        <a data-clog-click="true" data-clog-ui-component="hc_primary_card" data-clog-ui-element="link_tips_tricks" href="https://ekompanija.tawk.help/article/podr%C5%A1ka-vezom-na-daljinu" target="_blank" class="tile" title="Tips, Tricks &amp; More">
          <img class="card_image" border="0" src="https://www.questionpro.com/qp_userimages/sub-3/3342920/Recurso-14x_3.png">
          <h2 class="card_title">Programi za vezu na daljinu</h2>
          <p class="card_description">Ovde možete preuzeti aplikacije kako bi agenti naše tehničke podrške mogli da pristupe vašem računaru na daljinu.</p>
        </a>
      </div>
    </div>
  </div>
</section>
