<div class="p-flex-column">
  <kodit-field fieldLabel="Tekst pre računa" class="p-fluid">
    <kodit-textarea-input
      [ctrlPlaceholder]="'Text pre računa'"
      [formCtrl]="form.controls.preamble"
    ></kodit-textarea-input>
  </kodit-field>
  <kodit-field fieldLabel="Tekst posle računa" class="p-fluid">
    <kodit-textarea-input
      [ctrlPlaceholder]="'Text posle računa'"
      [formCtrl]="form.controls.message"
    ></kodit-textarea-input>
  </kodit-field>
</div>
