import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  AuthService,
  ConfigService,
  GodinaRacunaDropdownService,
  LocationService,
  MixpanelService,
  RacunService,
  SefService,
  SharedService,
  SummaryService,
} from '@kodit/core/services';
import {
  CancelPredracunCommand,
  FileResponse,
  FilterChipDto,
  GetRacunRelevantniDokumentListZippedQuery,
  GetRacunTableQuery,
  IGetSefHealthStatusResponse,
  IMailReceiverDto,
  IPaginatedResultOfRacunTableDto,
  IRacunFilterDto,
  IRacunTableDto,
  PackageType,
  PaginatedResultOfRacunTableDtoAndRacunSaldoDto,
  PredracuniClient,
  RacunFilterDto,
  RacuniClient,
  RacunTableDto,
  SefClient,
  SendRacunListToSefCommand,
  StatusRacuna,
  StatusUplateRacuna,
  SyncStatusPojedinacnogIzlaznihRacunaCommand,
  TipPristupa,
  TipRacuna,
  TipStranke,
  UlazneFaktureClient,
  VezaniRacunDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import {
  ActionClass,
  ActionMode,
  ActionType,
  OnTableInit,
  SelectMode,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { RacunFilterComponent } from '../racun-filter/racun-filter.component';
import * as printJS from 'print-js';
import { RacunFilterService } from '../racun-filter/racun-filter.service';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogTypedInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { PosaljiElektronskiFormComponent } from '../posalji-elektronski-form/posalji-elektronski-form.component';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import { StornoIzlazniRacunFormComponent } from '../storno-izlazni-racun-form/storno-izlazni-racun-form.component';
import { CancelIzlazniRacunFormComponent } from '../cancel-izlazni-racun-form/cancel-izlazni-racun-form.component';
import { PosaljiNaSefFormComponent } from '../posalji-na-sef-form/posalji-na-sef-form.component';
import {
  nameofFactory,
  SERBIA_CURRENCY_ALPHA_CHAR,
  StopaPdv,
  toCamelCase,
} from '@kodit/core/shared';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { finalize } from 'rxjs/operators';
import { RacunUploadFormComponent } from '../racun-upload-form/racun-upload-form';
import { TableComponent } from 'libs/core/shared-ui/src/lib/table/table/table.component';
import { RacunExportFormComponent } from '../racun-export-form/racun-export-form.component';
import { PosaljiIzjavuZaOdbitakPdvFormComponent } from '../posalji-izjavu-za-odbitak-pdv-form/posalji-izjavu-za-odbitak-pdv-form/posalji-izjavu-za-odbitak-pdv-form.component';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from '../state/filters/filter.selectors';
import { setFilter } from '../state/filters/filter.actions';
import { UplatnicaFormComponent } from '../uplatnica-form/uplatnica-form.component';
import { InitService } from '../../../../../core-ui/src/lib/init.service';

@Component({
  selector: 'kodit-racun-table',
  templateUrl: './racun-table.component.html',
  styleUrls: ['./racun-table.component.scss'],
})
export class RacunTableComponent
  implements
    OnInit,
    OnDestroy,
    OnTableInit,
    OnDynamicDialogTypedInit<PosaljiElektronskiFormComponent>,
    OnDynamicDialogTypedInit<StornoIzlazniRacunFormComponent>,
    OnDynamicDialogTypedInit<CancelIzlazniRacunFormComponent> {
  private _subs: Subscription = new Subscription();

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Helpers */
  private readonly _racunTableHeaders: { [key in TipRacuna]?: string } = {
    [TipRacuna.IZLAZNA_FAKTURA]: 'Izlazne fakture',
    [TipRacuna.ULAZNA_FAKTURA]: 'Ulazne fakture',
    [TipRacuna.PREDRACUN]: 'Predračuni',
    [TipRacuna.OTPREMNICA]: 'Otpremnice',
    [TipRacuna.DOKUMENT_O_SMANJENJU]: 'Dokumenti o smanjenju',
    [TipRacuna.DOKUMENT_O_POVECANJU]: 'Dokumenti o povećanju',
    [TipRacuna.AVANSNI_RACUN]: 'Avansni računi',
    [TipRacuna.RADNI_NALOG]: 'Radni nalozi',
    [TipRacuna.PONUDA]: 'Ponude',
    [TipRacuna.EMPTY]: 'Registar računa',
  };

  /** Props */
  paginatedData: IPaginatedResultOfRacunTableDto;
  tableConfig: TableConfig;
  racuniTable: RacunTableDto[] = [];

  strankaId: number;
  //save filter and paginate variables
  tableSessionStorage: IRacunFilterDto = {};
  filters: IRacunFilterDto;
  routerChangeDetected: boolean;

  chipItems: FilterChipDto[] = [];
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isSefActive = false;
  isFirstLoad = true;
  jeDomaciRacun = true;
  jeMojaFirmaUSistemuPdv = true;

  /**ViewChild */
  @ViewChild(TableComponent) racunTable: TableComponent;

  /** I/O */
  @Input() tipRacuna: TipRacuna = TipRacuna.EMPTY;
  @Input() vrstaRacuna: VrstaRacuna = VrstaRacuna.EMPTY;

  constructor(
    private _client: RacuniClient,
    private _sefClient: SefClient,
    private _alertService: AlertService,
    private _configService: ConfigService,
    private _route: ActivatedRoute,
    private _sharedService: SharedService,
    private _racunFilterService: RacunFilterService,
    private _dialogService: DynamicDialogService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _predracunClient: PredracuniClient,
    private _summaryService: SummaryService,
    private _router: Router,
    private _ulaznaFakturaClient: UlazneFaktureClient,
    private _loader: LoaderService,
    private _sefService: SefService,
    private _godinaService: GodinaRacunaDropdownService,
    private _racunService: RacunService,
    private _mixpanelService: MixpanelService,
    private _authService: AuthService,
    private _storage: Store,
    private _initService: InitService
  ) {}

  ngOnInit(): void {
    // check if page is reloaded
    if (this._router.onSameUrlNavigation == 'reload') {
      this.routerChangeDetected = true;
    }
    // get router data
    this._route.data.subscribe((data) => {
      if (data['tipRacuna']) {
        this.tipRacuna = data['tipRacuna'] as TipRacuna;
      }
      if (data['vrstaRacuna']) {
        this.vrstaRacuna = data['vrstaRacuna'] as VrstaRacuna;
      }
    });
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });

    this._subs.add(
      this._initService.getKonfiguracijuMojeFirme.subscribe((res) => {
        this.jeMojaFirmaUSistemuPdv = res?.uSistemuPDVa;
      })
    );

    // this._subs.add(
    //   this._configService.getShouldShowSyncWithSefStatusButton.subscribe(
    //     (res) => {
    //       this.shouldShowSyncWithSefStatusButton = res;
    //     }
    //   )
    // );

    //citanje filtera iz memorije(ngrx)
    this._subs.add(
      this._storage
        .select(selectFilterByKey(this._getFilterSearchKey()))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new RacunFilterDto({
              tip: this.tipRacuna,
              strankaId: this.strankaId,
              vrstaRacuna: this.vrstaRacuna,
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );

    // config table
    this.setTableConfig().then();

    // this._subs.add(
    //   this._racunFilterService.getAdvanceFilterSubmitted.subscribe(
    //     (filterResult) => {
    //       if (filterResult?.shouldApplyFilter) {
    //         this._updateCurrentPage();
    //         filterResult.filterData.pageNumber = this._skipFirst;
    //         filterResult.filterData.pageSize = this.numberOfRowsDisplayed;
    //         this.tableConfig.advancedFilter.data = filterResult.filterData;

    //         // if (!this.routerChangeDetected) {
    //         //   this._racunStorageService.set2(
    //         //     this.tipRacuna,
    //         //     this.vrstaRacuna,
    //         //     filterResult.filterData
    //         //   );
    //         // }

    //         //this._load(this.tableConfig.advancedFilter.data);
    //       }
    //     }
    //   )
    // );

    this._subs.add(
      this._sefService.getIsSefActive.subscribe(
        (isSefActive: IGetSefHealthStatusResponse) => {
          if (this._isSefActive != isSefActive.isActive && !this.isFirstLoad) {
            this._isSefActive = isSefActive.isActive;
            //this.updateFilterDataAndReload();
          }
        }
      )
    );

    this._subs.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this._load();
      })
    );
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      selectMode: SelectMode.MULTI,
      tableHeader: this._racunTableHeaders[this.tipRacuna],
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane račune?',
      deleteMessage: 'Da li ste sigurni da želite da obrišete račun {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      // isSelectableRowDisabled: (rowData: IRacunTableDto) => {
      //   return rowData?.vezaniRacuniDto?.length > 0;
      // },
      columns: [
        {
          header: 'Tip',
          type: 'badge',
          field: 'tipRacunaDto',
          subField: 'tipStr',
          styleClassField: 'tipBadgeStr',
          styleClass: 'tip-racuna',
          tooltipField: 'opis',
          isVisible: this.tipRacuna == TipRacuna.EMPTY,
        },
        {
          field: 'broj',
          header: 'Broj',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToInfo(itemIndex),
          columns: [
            {
              field: 'vezaniRacuniDto',
              subField: 'label',
              header: 'Veza',
              type: 'link',
              linkCallbackFunction: (itemIndex: number, subIndex: number) =>
                this._goToVezaniRacunInfo(itemIndex, subIndex),
            },
          ],
        },
        {
          field: 'originalanBroj',
          header: 'Originalni Broj',
          type: 'text',
          isVisible: this.vrstaRacuna == VrstaRacuna.ULAZNI,
          linkCallbackFunction: (itemIndex: number) =>
            this._goToInfo(itemIndex),
        },
        {
          field: 'datumSlanja',
          header: 'Poslato',
          type: 'text',
          isVisible:
            this.tipRacuna != TipRacuna.OTPREMNICA &&
            this.tipRacuna != TipRacuna.RADNI_NALOG &&
            this.tipRacuna != TipRacuna.PONUDA &&
            this.tipRacuna != TipRacuna.PREDRACUN &&
            this.vrstaRacuna == VrstaRacuna.IZLAZNI,
        },
        {
          field: 'datumIzdavanja',
          header: 'Izdavanje',
          type: 'text',
          isVisible: this.tipRacuna == TipRacuna.ULAZNA_FAKTURA,
        },
        {
          field: 'datumPrometa',
          header: 'Promet',
          type: 'text',
          isVisible:
            this.tipRacuna != TipRacuna.RADNI_NALOG &&
            this.tipRacuna != TipRacuna.AVANSNI_RACUN &&
            this.tipRacuna != TipRacuna.PREDRACUN &&
            this.tipRacuna != TipRacuna.PONUDA,
        },
        {
          field: 'datumValute',
          header:
            this.tipRacuna === TipRacuna.PREDRACUN ||
            this.tipRacuna === TipRacuna.PONUDA
              ? 'Datum važenja'
              : 'Valuta',
          type: 'text',
          isVisible:
            this.tipRacuna !== TipRacuna.AVANSNI_RACUN &&
            this.tipRacuna !== TipRacuna.RADNI_NALOG,
        },
        {
          field: 'datumValute',
          header: 'Datum uplate',
          type: 'text',
          isVisible: this.tipRacuna == TipRacuna.AVANSNI_RACUN,
        },
        {
          field: 'stranka',
          header: 'Stranka',
          type: 'link',
          isVisible: !this.strankaId,
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },
        {
          field: 'imeLokacijeMestaIsporuke',
          header: 'Isporuka',
          type: 'text',
          isVisible: this.tipRacuna === TipRacuna.OTPREMNICA,
        },
        {
          field: 'iznos',
          header: 'Iznos',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
          columns: [
            {
              field: 'iznosRSD',
              header: '',
              type: 'currency',
              shouldDisplayByCondition: (fieldValue: number) => {
                return this._shouldDisplayIznosRsd(fieldValue);
              },
            },
          ],
        },
        {
          field: 'vrstaRacunaStr',
          header: 'Vrsta računa',
          type: 'badge',
          isVisible: this.tipRacuna === TipRacuna.EMPTY && !this.strankaId,
          styleClass: 'vrsta-racuna-table',
        },
        {
          field: 'statusRacunaDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-racuna',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'iznos',
          type: 'badge',
          isVisible: this.tipRacuna !== TipRacuna.RADNI_NALOG,
          columns: [
            {
              field: 'crfStatusDto',
              subField: 'statusStr',
              styleClassField: 'statusBadgeStr',
              header: 'CRF',
              styleClass: 'status-crf',
              type: 'badge',
            },
          ],
        },
        {
          field: 'statusRadnogNalogaDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-radnog-naloga',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
          isVisible: this.tipRacuna === TipRacuna.RADNI_NALOG,
        },
        {
          field: 'statusUplateDto',
          subField: 'statusStr',
          header:
            this.tipRacuna == TipRacuna.AVANSNI_RACUN
              ? 'Iskorišćenost'
              : 'Status uplate',
          styleClass: 'status-uplate-racuna',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
          isVisible:
            this.tipRacuna != TipRacuna.OTPREMNICA &&
            this.tipRacuna != TipRacuna.RADNI_NALOG,
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Uvezi',
          icon: 'fas fa-file-import',
          hasAccessTooltip: 'Uvezite račun iz drugog programa',
          noAccessTooltip: 'Nemate ovlašćenja za uvoz računa',
          callback: () => {
            this.dialogConfig.header = 'Uvoz računa';
            this.dialogConfig.hideFooter = true;
            this.dialogConfig.maximisable = false;
            this.openDialog(RacunUploadFormComponent);
          },
          isVisible: this._shouldDisplayUvozRacuna(),
        },
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Izvezi',
          icon: 'fas fa-file-export',
          noAccessTooltip: 'Nemate ovlašćenja za izvoz računa',
          callback: (result: RacunTableDto[]) => {
            this.dialogConfig.header = 'Izvoz računa';
            this.dialogConfig.maximisable = false;
            this.dialogConfig.data = {
              racuni: result.map((res) => res.id),
            };
            this.dialogConfig.customSubmitButton = {
              icon: 'fas fa-file-export',
              label: 'Izvezi',
            };
            this.openDialog(RacunExportFormComponent);
          },
          isVisible: this._shouldDisplayIzvozRacuna(),
        },
        {
          type: ActionType.CUSTOM,
          tipPristupa: this._sharedService.racunCreateTipPristupa[
            this.tipRacuna
          ],
          actionClass: ActionClass.OUTLINED,
          label: 'Sinhronizuj statuse',
          icon: 'fa-light fa-rotate',
          hasAccessTooltip: 'Sinhronizujte statuse izlaznih računa sa SEF-om',
          shouldDisableWhenSefInactive: true,
          noAccessTooltip:
            'Nemate ovlašćenja za sinhronizaciju statusa izlaznih računa',
          isVisible: this._shouldDisplaySyncStatusIzlaznogRacuna(),
          callback: () => {
            this._subs.add(
              this._sefClient.syncStatuseIzlaznihRacuna().subscribe((res) => {
                this._alertService.addSuccessMsg(res.data);
                this._load();
              })
            );
          },
        },
        /* Konvertovanje vise otpremnica u izlaznu fakturu*/
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Konvertuj u fakturu',
          icon: 'fa-light fa-copy',
          hasAccessTooltip:
            'Selektujte otpremnice klikom na dugme ispred naziva otpremnice i konvertujte ih u izlaznu fakturu',
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          isVisible: this.tipRacuna == TipRacuna.OTPREMNICA,
          callback: (result: RacunTableDto[]) => {
            if (result.every((res) => res.rutiranjeStranke.strankaId == 0)) {
              this._alertService.addWarnMsg(
                'Akcija konvertovanja nije moguća. Otpremnice moraju sadržati stranku.'
              );
              return;
            }
            if (
              !result.every(
                (val, i, arr) =>
                  val.rutiranjeStranke.strankaId ===
                  arr[0].rutiranjeStranke.strankaId
              )
            ) {
              this._alertService.addWarnMsg(
                'Akcija konvertovanja nije moguća. Otpremnice moraju biti vezane za istog kupca.'
              );
              return;
            }
            //Proveravamo da li je neka otpremnica vec konvertovana u fakturu
            if (
              !result.every(
                (racunTableDto) =>
                  !this._racunService.hasVezaniRacunPoTipuRacuna(
                    racunTableDto.vezaniRacuniDto,
                    TipRacuna.IZLAZNA_FAKTURA
                  )
              )
            ) {
              this._alertService.addWarnMsg(
                'Akcija konvertovanja nije moguća. Neka od selektovanih otpremnica je već konvertovana.'
              );
              return;
            }
            this._locationService.routeToRacunCreateForOtpremnica(
              result.map((otpremnica) => otpremnica.id)
            );
          },
        },
        /** Preuzimanje vise UF u PDF formatu */
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Preuzmi PDF',
          icon: 'fa-solid fa-download',
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Preuzmite PDF fajlove selektovanih računa',
          noAccessTooltip: 'Nemate ovlašćenja za preuzimanje PDF-a računa',
          callback: (result: RacunTableDto[]) => {
            this._getRacunRelevantniDokumentListZipped(
              result.map((racun) => racun.id)
            );
          },
        },
        /* Slanje vise IF na SEF*/
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Pošalji na SEF',
          icon: 'fa-solid fa-paper-plane',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip: 'Pošaljite odabrane račune na SEF',
          noAccessTooltip: 'Nemate ovlašćenja za slanje računa na SEF',
          isVisible:
            this.tipRacuna == TipRacuna.IZLAZNA_FAKTURA &&
            (this.isFirstLoad || this._isSefActive),
          callback: (result: RacunTableDto[]) => {
            if (result.some((x) => !x.mozeNaSef)) {
              this._alertService.addWarnMsg(
                'Neke od faktura nije moguće poslati na SEF!'
              );
              return;
            }
            this._loader.setLoadingText = 'Računi se šalju na SEF...';
            this._loader.setShowLoader = true;
            this._sendRacuniToSef(result.map((racun) => racun.id));
          },
        },
        /* Unos racuna sa tabele*/
        {
          type: ActionType.CREATE,
          tipPristupa: this._sharedService.racunCreateTipPristupa[
            this.tipRacuna
          ],
          joyrideStep: 'izlaznaFakturaKreiranjeStep1',
          hasAccessTooltip: 'Unesite račun',
          noAccessTooltip: 'Nemate ovlašćenja za unos računa',
          isVisible:
            this.isSingleRacunMode &&
            this.tipRacuna !== TipRacuna.AVANSNI_RACUN,
          callback: () => this._goToCreateRacun(this.tipRacuna),
        },
        /* Unos racuna preko info strane stranke */
        {
          type: ActionType.DROPDOWN,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Unesite račun',
          noAccessTooltip: 'Nemate ovlašćenja za unos računa',
          label: 'Unos',
          icon: 'fas fa-caret-down',
          isVisible: !this.isSingleRacunMode,
          mutliActions: [
            {
              type: ActionType.DROPDOWN_ITEM,
              label: this._sharedService.racunNames[TipRacuna.IZLAZNA_FAKTURA],
              icon: 'fas fa-plus',
              isVisible: this.vrstaRacuna === VrstaRacuna.IZLAZNI,
              hasAccessTooltip: 'Napravite novu izlaznu fakturu',
              callback: () => {
                this._goToCreateRacun(TipRacuna.IZLAZNA_FAKTURA);
              },
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: this._sharedService.racunNames[TipRacuna.ULAZNA_FAKTURA],
              icon: 'fas fa-plus',
              isVisible: this.vrstaRacuna === VrstaRacuna.ULAZNI,
              hasAccessTooltip: 'Napravite novu ulaznu fakturu',
              callback: () => {
                this._goToCreateRacun(TipRacuna.ULAZNA_FAKTURA);
              },
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: this._sharedService.racunNames[TipRacuna.PREDRACUN],
              icon: 'fas fa-plus',
              isVisible: this.vrstaRacuna === VrstaRacuna.IZLAZNI,
              hasAccessTooltip: 'Napravite nov predračun',
              callback: () => {
                this._goToCreateRacun(TipRacuna.PREDRACUN);
              },
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: this._sharedService.racunNames[TipRacuna.OTPREMNICA],
              isVisible: this.vrstaRacuna === VrstaRacuna.IZLAZNI,
              icon: 'fas fa-plus',
              hasAccessTooltip: 'Napravite novu otpremnicu',
              callback: () => {
                this._goToCreateRacun(TipRacuna.OTPREMNICA);
              },
            },
            // {
            //   type: ActionType.DROPDOWN_ITEM,
            //   label: this._sharedService.racunNames[
            //     TipRacuna.DOKUMENT_O_SMANJENJU
            //   ],
            //   icon: 'fas fa-plus',
            //   hasAccessTooltip: 'Napravite novi dokument o smanjenju',
            //   callback: () => {
            //     this._goToCreateRacun(TipRacuna.DOKUMENT_O_SMANJENJU);
            //   },
            // },
            // {
            //   type: ActionType.DROPDOWN_ITEM,
            //   label: this._sharedService.racunNames[
            //     TipRacuna.DOKUMENT_O_POVECANJU
            //   ],
            //   icon: 'fas fa-plus',
            //   hasAccessTooltip: 'Napravite novi dokument o povećanju',
            //   callback: () => {
            //     this._goToCreateRacun(TipRacuna.DOKUMENT_O_POVECANJU);
            //   },
            // },
          ],
        },
        /* Unos avansnog racuna iz racun table*/
        {
          type: ActionType.DROPDOWN,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Unesite račun',
          noAccessTooltip: 'Nemate ovlašćenja za unos računa',
          label: 'Unos',
          icon: 'fas fa-caret-down',
          isVisible: this.tipRacuna === TipRacuna.AVANSNI_RACUN,
          mutliActions: [
            {
              type: ActionType.DROPDOWN_ITEM,
              label: '20%',
              callback: () => {
                this._goToCreateAvansniRacun(StopaPdv.OSNOVNA);
              },
              isVisible: this.jeMojaFirmaUSistemuPdv,
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: '10%',
              callback: () => {
                this._goToCreateAvansniRacun(StopaPdv.POSEBNA);
              },
              isVisible: this.jeMojaFirmaUSistemuPdv,
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: '8%',
              callback: () => {
                this._goToCreateAvansniRacun(
                  StopaPdv.POSEBNA_POLJOPRIVREDNO_GAZDINSTVO
                );
              },
              isVisible: this.jeMojaFirmaUSistemuPdv,
            },
            {
              type: ActionType.DROPDOWN_ITEM,
              label: '0%',
              callback: () => {
                this._goToCreateAvansniRacun(StopaPdv.BEZ_PDV);
              },
            },
          ],
        },
      ],
      rowActions: [
        /* Izmena racuna */
        {
          type: ActionType.EDIT,
          tipPristupa: this._sharedService.racunUpdateTipPristupa[
            this.tipRacuna
          ],
          hasAccessTooltip: 'Izmenite račun',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu računa',
          callback: (index: number) => {
            this._goToEdit(index);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayEdit(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.tipRacunaDto.tip,
              rowData.vezaniRacuniDto
            );
          },
        },
        /* Brisanje racuna */
        {
          type: ActionType.DELETE,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Obrišite račun',
          noAccessTooltip: 'Nemate ovlašćenja za brisanje računa',
          callback: (index: number) => {
            // this._subs.add(
            //   this._client.delete(index).subscribe(() => {
            //     const forDelete = this.racuniTable.find((x) => x.id);
            //     this._alertService.addSuccessMsg(
            //       `Račun ${forDelete.broj} je obrisan.`
            //     );
            //     this.racuniTable.splice(this.racuniTable.indexOf(forDelete), 1);
            //   })
            // );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayDelete(rowData.statusRacunaDto.status);
          },
        },
        /* Slanje racuna na mail */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          label: 'Pošalji na E-Mail',
          icon: 'fa-solid fa-envelope',
          hasAccessTooltip: 'Pošaljite račun na E-mail klijentu',
          noAccessTooltip: 'Nemate ovlašćenja za slanje računa na E-mail',
          callback: (index: number) => {
            this.dialogConfig.header = 'Elektronsko slanje';
            const sendTo: IMailReceiverDto[] = [];
            const sendCc: IMailReceiverDto[] = [];
            let customPoruka: IMailReceiverDto;
            this.dialogConfig.data = {
              strankaId: this._getPaginatedItem(index).rutiranjeStranke
                .strankaId,
              racunTip: this._getPaginatedItem(index).tipRacunaDto.tip,
              racunId: this._getPaginatedItem(index).id,
              sendTo: sendTo,
              sendCc: sendCc,
              customPoruka: customPoruka,
              stampatiNaEngleskom:
                this._getPaginatedItem(index).valutaText !==
                SERBIA_CURRENCY_ALPHA_CHAR,
            };
            this.dialogConfig.customSubmitButton = {
              icon: 'fa-solid fa-envelope',
              label: 'Pošalji',
            };
            this.openDialog(PosaljiElektronskiFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplaySendToEmail(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna
            );
          },
        },
        /* Slanje racuna na sef */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          icon: 'fas fa-paper-plane',
          label: 'Pošalji na SEF',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip: 'Pošaljite račun na SEF',
          noAccessTooltip: 'Nemate ovlašćenje za slanje računa na SEF',
          callback: (index: number) => {
            // this._confirmationService.confirm({
            //   header: 'Potvrda slanja',
            //   message: 'Da li želite da posaljete na SEF?',
            //   acceptLabel: 'Pošalji',
            //   rejectLabel: 'Odustani',
            //   rejectButtonStyleClass: 'p-button-outlined',
            //   accept: () => this._sendToSef(this.racuniTable[index].id),
            // });
            this.dialogConfig.header = `Slanje na SEF - račun ${
              this._getPaginatedItem(index).broj
            }`;
            this.dialogConfig.customSubmitButton = {
              icon: 'fas fa-paper-plane',
              label: 'Pošalji',
              class: 'mw-90',
            };
            this.dialogConfig.data = {
              racunId: this._getPaginatedItem(index).id,
              tip: this._getPaginatedItem(index).tipRacunaDto.tip,
              strankaId: this._getPaginatedItem(index).rutiranjeStranke
                .strankaId,
            };
            this.openDialog(PosaljiNaSefFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplaySendToSef(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.tipRacunaDto.tip,
              rowData.mozeNaSef
            );
          },
        },
        /* Oznacavanje kao poslato */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Označi kao poslato',
          hasAccessTooltip: 'Označite račun kao poslat',
          noAccessTooltip:
            'Nemate ovlašćenja za označavanje računa kao poslatog',
          icon: 'fa-solid fa-check',
          callback: (index: number) => {
            this._oznaciKaoPoslato(index);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return (
              (rowData.statusRacunaDto.status === StatusRacuna.NACRT &&
                rowData.vrstaRacuna) === VrstaRacuna.IZLAZNI
            );
          },
        },
        /* Kreiranje uplatnice */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Kreiraj uplatnicu',
          hasAccessTooltip: 'Napravite uplatnicu za račun',
          noAccessTooltip: 'Nemate ovlašćenja za kreiranje uplatnice za račun',
          icon: 'fa-light fa-money-check-dollar',
          callback: (index: number) => {
            this.dialogConfig.header = 'Kreiranje uplatnice';
            this.dialogConfig.maximisable = false;
            this.dialogConfig.customSubmitButton = { label: 'Kreiraj' } as any;
            this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
            this.dialogConfig.data = {
              racunId: this._getPaginatedItem(index).id,
            };
            this.openDialog(UplatnicaFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayUplatnica(
              rowData.tipRacunaDto.tip,
              rowData.rutiranjeStranke.tipStranke
            );
          },
        },
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Preuzmi PDF sa izjavom o poreklu robe',
          hasAccessTooltip: 'Preuzmite pdf',
          noAccessTooltip: 'Nemate ovlašćenja za preuzimanje pdf-a',
          icon: 'fas fa-download',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .getIzjavaOPorekluPdf(this._getPaginatedItem(index).id)
                .subscribe((result) => {
                  this._sharedService.downloadBlobAsPDF(
                    result.data,
                    result.fileName
                  );
                })
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayDownloadIzjavaOPoreklu(
              rowData.valutaText,
              rowData.vrstaRacuna,
              rowData.tipRacunaDto.tip
            );
          },
        },
        /*Sinhronizacija racuna sa sefom */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.ULAZNA_FAKTURA_CRUD,
          icon: 'fa-light fa-rotate',
          label: 'Sinhronizuj sa SEF-om',
          shouldDisableWhenSefInactive: true,
          hasAccessTooltip: 'Izvršite sinhronizaciju statusa računa sa SEF-om',
          noAccessTooltip:
            'Nemate ovlašćenje za sinhronizaciju računa sa SEF-om',
          callback: (index: number) => {
            this._loader.setLoadingText = 'Sinhronizacija sa SEF-om u toku...';
            this._loader.setShowLoader = true;
            this._subs.add(
              this._sefClient
                .syncStatusPojedinacnogIzlaznogRacuna({
                  racunId: this._getPaginatedItem(index).id,
                } as SyncStatusPojedinacnogIzlaznihRacunaCommand)
                .pipe(
                  finalize(() => {
                    this._loader.reset();
                  })
                )
                .subscribe((res) => {
                  this._alertService.addSuccessMsg(res.data);
                  this._load();
                })
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplaySyncStatusaIzlazneFakture(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.tipRacunaDto.tip
            );
          },
        },
        /*Otkazivanje racuna */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          icon: 'fa-solid fa-ban',
          label: 'Otkažite račun',
          hasAccessTooltip: 'Otkažite poslati račun',
          noAccessTooltip: 'Nemate ovlašćenja za otkazivanje računa',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this.dialogConfig.data = {
              racunId: this._getPaginatedItem(index).id,
              brojRacuna: this._getPaginatedItem(index).broj,
            };
            this.dialogConfig.customSubmitButton = {
              icon: 'fas fa-save',
              label: 'Da',
              class: 'mw-90',
            };
            this.dialogConfig.customCancelButton = {
              icon: 'far fa-window-close',
              label: 'Ne',
            };
            this.openDialog(CancelIzlazniRacunFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayCancel(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna
            );
          },
        },
        /*Storniranje racuna */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          icon: 'fa-solid fa-s',
          label: 'Storniraj račun',
          hasAccessTooltip: 'Stornirajte poslati račun',
          noAccessTooltip: 'Nemate ovlašćenja za storniranje računa',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this.dialogConfig.data = {
              racunId: this._getPaginatedItem(index).id,
              brojRacuna: this._getPaginatedItem(index).broj,
            };
            this.dialogConfig.header = 'Storniranje fakture';
            this.dialogConfig.customSubmitButton = {
              icon: 'fas fa-check',
              label: 'Storniraj',
              class: 'mw-90',
            };
            this.dialogConfig.customCancelButton = {
              icon: 'far fa-window-close',
              label: 'Otkaži',
            };
            this.dialogConfig.setDialogSize = DialogSize.MEDIUM_LARGE;
            this.openDialog(StornoIzlazniRacunFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayStorno(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.tipRacunaDto.tip,
              rowData.vezaniRacuniDto
            );
          },
        },
        /*Slanje izjave za odbitak PDV */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZLAZNA_FAKTURA_CRUD,
          label: 'Pošalji izjavu za odbitak PDV',
          icon: 'fa-solid fa-envelope',
          hasAccessTooltip:
            'Pošaljite izjavu za odbitak PDV na E-mail klijentu',
          noAccessTooltip:
            'Nemate ovlašćenja za slanje izjave za odbitak na E-mail',
          callback: (index: number) => {
            this.dialogConfig.header = 'Slanje izjave za odbitak PDV';
            let sendTo: IMailReceiverDto[] = [];
            let sendCc: IMailReceiverDto[] = [];
            let customPoruka: IMailReceiverDto;
            this.dialogConfig.data = {
              strankaId: this._getPaginatedItem(index).rutiranjeStranke
                .strankaId,
              racunTip: this._getPaginatedItem(index).tipRacunaDto.tip,
              racunId: this._getPaginatedItem(index).id,
              sendTo: sendTo,
              sendCc: sendCc,
              customPoruka: customPoruka,
            };
            this.dialogConfig.customSubmitButton = {
              icon: 'fa-solid fa-envelope',
              label: 'Pošalji',
            };
            this.openDialog(PosaljiIzjavuZaOdbitakPdvFormComponent);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplaySendIzjavaZaOdbitakPdv(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna
            );
          },
        },
        /* stampanje domaceg racuna */
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fas fa-print',
          hasAccessTooltip: 'Odštampajte račun u PDF formatu',
          noAccessTooltip: 'Nemate ovlašćenja za štampanje računa',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .getPdf(this._getPaginatedItem(index).id)
                .subscribe((result) => {
                  const fileURL = URL.createObjectURL(result.data);
                  printJS(fileURL);
                })
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayPrint(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.valutaText
            );
          },
        },
        /* stampanje ino racuna */
        {
          mode: ActionMode.SINGLE,
          type: ActionType.DROPDOWN,
          icon: 'fas fa-print',
          hasAccessTooltip: 'Odštampajte račun u PDF formatu',
          noAccessTooltip: 'Nemate ovlašćenja za štampanje računa',
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayPrintDropdown(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna,
              rowData.valutaText
            );
          },
          mutliActions: [
            {
              mode: ActionMode.SINGLE,
              type: ActionType.DROPDOWN_ITEM,
              label: 'Srpski',
              icon: 'fa-light fa-location-dot',
              hasAccessTooltip: 'Odštampaj na srpskom',
              callback: (index: number) => {
                this._subs.add(
                  this._client
                    .getPdf(this._getPaginatedItem(index).id)
                    .subscribe((result) => {
                      const fileURL = URL.createObjectURL(result.data);
                      printJS(fileURL);
                    })
                );
              },
            },
            {
              mode: ActionMode.SINGLE,
              type: ActionType.DROPDOWN_ITEM,
              label: 'Engleski',
              icon: 'fa-light fa-globe',
              hasAccessTooltip: 'Odštampaj na engleskom',
              callback: (index: number) => {
                this._subs.add(
                  this._client
                    .getPdf(this._getPaginatedItem(index).id, true)
                    .subscribe((result) => {
                      const fileURL = URL.createObjectURL(result.data);
                      printJS(fileURL);
                    })
                );
              },
            },
          ],
        },
        /* Konvertovanje predracuna u izlaznu fakturu*/
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fas fa-exchange-alt',
          label: 'Konvertuj u fakturu',
          hasAccessTooltip: 'Konvertujte predračun u izlaznu fakturu',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje predračuna u  izlaznu fakturu',
          callback: (index: number) => {
            this._locationService.routeToRacunCreateForPredracun(
              this._getPaginatedItem(index).id
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayTransformToFaktura(rowData);
          },
        },
        /* Konvertovanje otpremnice u IF */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fas fa-exchange-alt',
          label: 'Konvertuj u fakturu',
          hasAccessTooltip: 'Konvertujte otpremnicu u izlaznu fakturu',
          callback: (index: number) => {
            this._locationService.routeToRacunCreateForOtpremnica([
              this._getPaginatedItem(index).id,
            ]);
          },
          shouldDisplayByCondition: (rowData: IRacunTableDto) => {
            return this._shouldDisplayKonvertujOtpremnicuUFakturu(rowData);
          },
        },
        /* Konvertovanje izlazne fakture u otpremnicu */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-exchange-alt',
          label: 'Konvertuj u otpremnicu',
          hasAccessTooltip: 'Konvertujte izlazni račun u otpremnicu',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje izlazne fakture u otpremnicu',
          callback: (index: number) => {
            this._locationService.routeToOtpremnicaCreateForRacun(
              this._getPaginatedItem(index).id
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayKonvertujUOtpremnicu(
              rowData?.vezaniRacuniDto?.length > 0,
              rowData.statusRacunaDto.status,
              rowData.tipRacunaDto.tip,
              rowData.vrstaRacuna
            );
          },
        },
        /* Konvertovanje ponude u predracun */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-exchange-alt',
          label: 'Konvertuj u predračun',
          hasAccessTooltip: 'Konvertujte Ponudu u predračun',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje ponude u predračun',
          callback: (index: number) => {
            this._locationService.routeToPredracunForPonuda(
              this._getPaginatedItem(index).id
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayPonudaToPredracun(
              rowData?.vezaniRacuniDto?.length > 0,
              rowData?.tipRacunaDto?.tip
            );
          },
        },
        /* Kreiraj pojedinačnu evidenciju PDV-a */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-books',
          label: 'Kreiraj pojedinačnu evidenciju PDV-a',
          hasAccessTooltip:
            'Napravite pojedinačnu pdv evidenciju od izlazne fakture',
          noAccessTooltip:
            'Nemate ovlašćenja za kreiranje pojedinačne pdv evidencije od izlazne fakture',
          callback: (index: number) => {
            this._confirmationService.confirm({
              message:
                'Da li ste sigurni da želite kreirate pojedinačnu evidenciju PDV-a koristeći podatke ovog računa ?',
              acceptLabel: 'Da',
              rejectLabel: 'Ne',
              header: 'Potvrdite',
              icon: 'fa-regular fa-circle-question',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._locationService.routeToPojedinacnaPDVEvidencijaForRacun(
                  this._getPaginatedItem(index).id
                );
              },
            });
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayKreirajPojedinacnuPdvEvidenciju(
              rowData.statusRacunaDto.status,
              rowData.tipRacunaDto.tip,
              rowData.vrstaRacuna
            );
          },
        },
        /* Konvertovanje predračuna u otpremnicu */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-boxes-packing',
          label: 'Konvertuj u otpremnicu',
          hasAccessTooltip: 'Konvertujte predračun u otpremnicu',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje predračuna u otpremnicu',
          callback: (index: number) => {
            this._locationService.routeToOtpremnicaForPredracun(
              this._getPaginatedItem(index).id
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayConvertPredracun(rowData);
          },
        },
        /* Konvertovanje predračuna u avansni racun */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-briefcase-arrow-right',
          label: 'Konvertuj u avansni račun',
          hasAccessTooltip: 'Konvertujte predračun u otpremnicu',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje predračuna u otpremnicu',
          callback: (index: number) => {
            this._locationService.routeToAvansniForPredracun(
              this._getPaginatedItem(index).id
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayConvertPredracun(rowData);
          },
        },
        {
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-file-lines',
          tipPristupa: TipPristupa.ULAZNA_FAKTURA_CRUD,
          hasAccessTooltip: 'Obradite račun',
          noAccessTooltip: 'Nemate ovlašćenja za obradu računa',
          shouldDisableWhenSefInactive: false,
          callback: (index: number) => {
            this._goToObrada(index, true);
            this._racunService.setJeObrada = true;
          },
          shouldDisplayByCondition: (rowData: IRacunTableDto) =>
            rowData.potrebnaObrada &&
            this._authService.hasPackage(PackageType[PackageType.VELEPRODAJA]),
        },
        /* Preuzimanje pdf računa */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Preuzmi PDF računa',
          hasAccessTooltip: 'Preuzmite pdf fajl računa',
          noAccessTooltip: 'Nemate ovlašćenja za preuzimanje pdf-a računa',
          icon: 'fas fa-download',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .getPdf(this._getPaginatedItem(index).id)
                .subscribe((result) => {
                  this._sharedService.downloadBlobAsPDF(
                    result.data,
                    result.fileName
                  );
                })
            );
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayDownload(
              rowData.statusRacunaDto.status,
              rowData.vrstaRacuna
            );
          },
        },
        /* Preuzimanje XML racuna */
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   icon: 'fa-regular fa-file-code',
        //   shouldDisableWhenSefInactive: true,
        //   hasAccessTooltip: 'Preuzmite xml/ubl fajl računa',
        //   noAccessTooltip: 'Nemate ovlašćenja za preuzimanje xml/ubl računa',
        //   callback: (index: number) => {
        //     this._subs.add(
        //       this._ulaznaFakturaClient
        //         .getUlazniRacunUblQuery(this._getPaginatedItem(index).id)
        //         .subscribe((result: FileResponse) => {
        //           const xmlFileUrl = URL.createObjectURL(result.data);
        //           this._sharedService.downloadUrlAsXml(
        //             xmlFileUrl,
        //             (result.data as any).fileName
        //           );
        //         })
        //     );
        //   },
        //   shouldDisplayByCondition: (rowData: RacunTableDto) => {
        //     return this._shouldDisplayDownloadXml(rowData.vrstaRacuna);
        //   },
        // },
        /* Ponistavanje predracuna */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Poništi predračun',
          hasAccessTooltip: 'Poništite predračun',
          noAccessTooltip: 'Nemate ovlašćenja za poništavanje predračuna',
          icon: 'fa-solid fa-xmark-large',
          callback: (index: number) => {
            this._confirmationService.confirm({
              message: 'Da li ste sigurni da želite da poništite predračun?',
              acceptLabel: 'Da',
              rejectLabel: 'Ne',
              header: ' Poništavanje predračuna',
              icon: 'fa-regular fa-circle-question',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._subs.add(
                  this._predracunClient
                    .cancelPredracun(
                      new CancelPredracunCommand({
                        racunId: this._getPaginatedItem(index).id,
                      })
                    )
                    .subscribe((res) => {
                      if (res.succeeded) {
                        this.updateFilterDataAndReload();
                        this._alertService.addSuccessMsg(res.data);
                      } else {
                        this._alertService.addWarnMsg(res.messages[0]);
                      }
                    })
                );
              },
            });
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayPonistiPredracun(rowData);
          },
        },
        /* Kreiranje duplikata */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Napravi duplikat',
          hasAccessTooltip: 'Napravite duplikat računa',
          noAccessTooltip: 'Nemate ovlašćenja za kreiranje duplikata računa',
          icon: 'fa-regular fa-clone',
          callback: (index: number) => {
            this._goToDuplicate(index);
          },
          shouldDisplayByCondition: (rowData: RacunTableDto) => {
            return this._shouldDisplayDuplicate(
              rowData.tipRacunaDto.tip,
              rowData.vrstaRacuna,
              rowData.originalanBroj
            );
          },
        },
      ],
      advancedFilter: {
        component: RacunFilterComponent,
        data: new RacunFilterDto(),
      },
    });
  }

  private _shouldDisplayUplatnica(
    tip: TipRacuna,
    tipStranke: TipStranke
  ): boolean {
    return (
      tip === TipRacuna.IZLAZNA_FAKTURA && tipStranke === TipStranke.FIZIKO_LICE
    );
  }

  private async _goToObrada(rowIndex: number, jeAutomatskaObrada: boolean) {
    await this._locationService.routeToObradaSefRacuna(
      this._getPaginatedItem(rowIndex).sefIdUlazni,
      this._getPaginatedItem(rowIndex).statusRacunaDto.status,
      jeAutomatskaObrada
    );
  }

  private _oznaciKaoPoslato(index: number) {
    this._loader.setLoadingText = 'Označavanje je u toku...';
    this._loader.setShowLoader = true;

    this._subs.add(
      this._client
        .oznaciKaoPoslato(this._getPaginatedItem(index).id)
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._load();
            this._alertService.addSuccessMsg(res.messages[0]);
          }
        })
    );
  }

  /** Filters */
  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: this._getFilterSearchKey(),
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _getFilterSearchKey(): string {
    return (
      `${VrstaRacuna[this.vrstaRacuna]}|${TipRacuna[this.tipRacuna]}` +
      (this.tipRacuna === TipRacuna.EMPTY ? '|' + this.strankaId : '')
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetRacunTableQuery>();

    const nameOfStatusi = nameof('statusi');
    const nameOfStatusiUplate = nameof('statusiUplateRacuna');
    const nameOfStranke = nameof('stranke');
    const nameOfOznake = nameof('oznake');

    if (removedChip.key.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusi.findIndex(
        (x) => x.status === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusi.splice(idx, 1);
    } else if (
      removedChip.key.toLowerCase() === nameOfStatusiUplate.toLowerCase()
    ) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusiUplateRacuna.findIndex(
        (x) => x.status === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusiUplateRacuna.splice(idx, 1);
    } else if (removedChip.key.toLowerCase() === nameOfStranke.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).stranke.findIndex(
        (x) => x.naziv === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).stranke.splice(idx, 1);
    } else if (removedChip.key.toLowerCase() === nameOfOznake.toLowerCase()) {
      const pureOznakaName = removedChip.value.replace("Oznaka: ","");
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).oznake.findIndex(
        (x) => x.naziv === pureOznakaName
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).oznake.splice(idx, 1);
    } else {
      this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
        removedChip.defaultValue;
    }
    this._updateStorage();
  }

  //#endregion Table configs

  //#region Routing

  private _goToStranka(itemIndex: number) {
    const racun = this._getPaginatedItem(itemIndex);
    this._locationService.routeToStrankaKartica(
      racun.rutiranjeStranke.strankaId,
      racun.rutiranjeStranke.tipStranke
    );
  }

  private _goToInfo(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToRacunKartica(
      racun.id,
      racun.tipRacunaDto.tip,
      this.vrstaRacuna
    );
  }

  private _goToVezaniRacunInfo(rowIndex: number, subIndex: number) {
    const racun = this._getPaginatedItem(rowIndex).vezaniRacuniDto[subIndex];
    this._locationService
      .routeToRacunKartica(racun.racunId, racun.tip, racun.vrsta)
      .then();
  }

  private _goToEdit(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToRacunEdit(
      racun.id,
      racun.tipRacunaDto.tip,
      this.vrstaRacuna
    );
  }

  private _goToDuplicate(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToDuplicate(
      racun.id,
      racun.tipRacunaDto.tip,
      this.vrstaRacuna
    );
  }

  private _goToCreateRacun(tip: TipRacuna) {
    this._locationService
      .routeToRacunCreate(tip, this.vrstaRacuna, this.strankaId)
      .then();
  }

  private _goToCreateAvansniRacun(stopaPDVa: number) {
    const strankaIdValue = this.strankaId ? this.strankaId.toString() : null;
    this._locationService.routeToAvansniRacunCreate(
      this.vrstaRacuna,
      stopaPDVa,
      strankaIdValue
    );
  }

  //#endregion Routing

  //#region Table buttons display logic

  private _shouldDisplayEdit(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    tipRacuna: TipRacuna,
    vezaniRacuni: VezaniRacunDto[]
  ) {
    if (
      (tipRacuna === TipRacuna.IZLAZNA_FAKTURA &&
        vezaniRacuni.some((el) => el.tip === TipRacuna.OTPREMNICA)) ||
      (tipRacuna === TipRacuna.OTPREMNICA &&
        vezaniRacuni.some((el) => el.tip === TipRacuna.IZLAZNA_FAKTURA))
    ) {
      return false;
    }
    return (
      status === StatusRacuna.NACRT &&
      (vrsta === VrstaRacuna.IZLAZNI || vrsta === VrstaRacuna.ULAZNI)
    );
  }

  private _shouldDisplayObradaUlaznogRacuna(
    status: StatusRacuna,
    vrsta: VrstaRacuna
  ) {
    return status === StatusRacuna.NACRT && vrsta === VrstaRacuna.ULAZNI;
  }

  private _shouldDisplaySyncStatusIzlaznogRacuna(): boolean {
    return false;
    return (
      this.vrstaRacuna === VrstaRacuna.IZLAZNI &&
      this.tipRacuna != TipRacuna.OTPREMNICA &&
      this.tipRacuna != TipRacuna.PREDRACUN
    );
  }

  private _shouldDisplayUvozRacuna(): boolean {
    return this.tipRacuna === TipRacuna.IZLAZNA_FAKTURA;
  }

  private _shouldDisplayIzvozRacuna(): boolean {
    return (
      this.vrstaRacuna === VrstaRacuna.IZLAZNI &&
      (this.tipRacuna === TipRacuna.IZLAZNA_FAKTURA ||
        this.tipRacuna === TipRacuna.AVANSNI_RACUN)
    );
  }

  private _shouldDisplayIznosRsd(fieldValue: number): boolean {
    return fieldValue !== null && fieldValue !== undefined;
  }

  private _shouldDisplayDelete(status: StatusRacuna) {
    return false;

    // obratiti paznju da ovako prikazuje i POCETNO_STANJE
    return status === StatusRacuna.NACRT || status === StatusRacuna.PRIHVACENO;
  }

  private _shouldDisplaySendToEmail(status: StatusRacuna, vrsta: VrstaRacuna) {
    return (
      (status === StatusRacuna.NACRT || status === StatusRacuna.POSLATO) &&
      vrsta === VrstaRacuna.IZLAZNI
    );
  }

  private _shouldDisplaySendIzjavaZaOdbitakPdv(
    status: StatusRacuna,
    vrsta: VrstaRacuna
  ) {
    return status === StatusRacuna.STORNIRANO && vrsta === VrstaRacuna.IZLAZNI;
  }

  private _shouldDisplaySendToSef(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    tip: TipRacuna,
    mozeNaSef: boolean
  ) {
    return (
      mozeNaSef &&
      vrsta === VrstaRacuna.IZLAZNI &&
      (status === StatusRacuna.NACRT ||
        status === StatusRacuna.GRESKA ||
        status === StatusRacuna.POSLATO ||
        status === StatusRacuna.OTKAZANO) &&
      (tip === TipRacuna.IZLAZNA_FAKTURA ||
        tip === TipRacuna.AVANSNI_RACUN ||
        tip === TipRacuna.DOKUMENT_O_SMANJENJU ||
        tip === TipRacuna.DOKUMENT_O_POVECANJU)
    );
  }

  private _shouldDisplaySyncStatusaIzlazneFakture(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    tip: TipRacuna
  ) {
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      status !== StatusRacuna.NACRT &&
      (tip === TipRacuna.IZLAZNA_FAKTURA ||
        tip === TipRacuna.AVANSNI_RACUN ||
        tip === TipRacuna.DOKUMENT_O_SMANJENJU ||
        tip === TipRacuna.DOKUMENT_O_POVECANJU)
    );
  }

  private _shouldDisplayCancel(status: StatusRacuna, vrsta: VrstaRacuna) {
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      (status === StatusRacuna.GRESKA || status === StatusRacuna.SLANJE)
    );
  }

  private _shouldDisplayStorno(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    tip: TipRacuna,
    vezaniRacuni: VezaniRacunDto[]
  ) {
    if (tip === TipRacuna.AVANSNI_RACUN) {
      return (
        vrsta === VrstaRacuna.IZLAZNI &&
        (status === StatusRacuna.PRIHVACENO ||
          status === StatusRacuna.ODBIJENO ||
          status === StatusRacuna.POSLATO) &&
        this._checkIfAvansniHasStornoIf(vezaniRacuni)
      );
    }

    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      tip !== TipRacuna.PREDRACUN &&
      tip !== TipRacuna.PONUDA &&
      (status === StatusRacuna.PRIHVACENO ||
        status === StatusRacuna.ODBIJENO ||
        status === StatusRacuna.POSLATO)
    );
  }

  private _shouldDisplayPrintDropdown(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    valutaTxt: string
  ) {
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      valutaTxt !== SERBIA_CURRENCY_ALPHA_CHAR &&
      (status === StatusRacuna.NACRT ||
        status === StatusRacuna.POSLATO ||
        status === StatusRacuna.PRIHVACENO)
    );
  }

  private _shouldDisplayPrint(
    status: StatusRacuna,
    vrsta: VrstaRacuna,
    valutaTxt: string
  ) {
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      valutaTxt === SERBIA_CURRENCY_ALPHA_CHAR &&
      (status === StatusRacuna.NACRT ||
        status === StatusRacuna.POSLATO ||
        status === StatusRacuna.PRIHVACENO)
    );
  }

  private _shouldDisplayDownload(status: StatusRacuna, vrsta: VrstaRacuna) {
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      (status === StatusRacuna.POSLATO || status === StatusRacuna.PRIHVACENO)
    );
  }

  private _shouldDisplayDownloadIzjavaOPoreklu(
    valuta: string,
    vrsta: VrstaRacuna,
    tip: TipRacuna
  ) {
    this._racunService.setJeDomaciRacun = valuta === 'RSD';

    this.jeDomaciRacun = valuta === 'RSD';
    return (
      vrsta === VrstaRacuna.IZLAZNI &&
      valuta !== 'RSD' &&
      tip === TipRacuna.IZLAZNA_FAKTURA
    );
  }

  private _shouldDisplayDownloadXml(vrsta: VrstaRacuna) {
    return vrsta === VrstaRacuna.ULAZNI;
  }

  // private _shouldDisplayUpload(status: StatusRacuna) {
  //   return status === StatusRacuna.PRIHVACENO;
  // }

  // private _shouldDisplayCreateIzmenuOsnovice(status: StatusRacuna) {
  //   return status === StatusRacuna.POSLATO;
  // }

  // private _shouldDisplayUploadPdf(status: StatusRacuna, vrsta: VrstaRacuna) {
  //   return status === StatusRacuna.PRIHVACENO && vrsta === VrstaRacuna.ULAZNI;
  // }

  private _shouldDisplayDuplicate(
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    originalanBroj: string
  ) {
    return (
      (vrsta === VrstaRacuna.IZLAZNI || vrsta === VrstaRacuna.ULAZNI) &&
      (tip === TipRacuna.PREDRACUN ||
        tip === TipRacuna.OTPREMNICA ||
        tip === TipRacuna.IZLAZNA_FAKTURA ||
        tip === TipRacuna.ULAZNA_FAKTURA) &&
      originalanBroj === null
    );
  }

  private _shouldDisplayTransformToFaktura(rowData: RacunTableDto) {
    return (
      (
        rowData.tipRacunaDto.tip === TipRacuna.PREDRACUN &&
        (rowData.statusRacunaDto.status === StatusRacuna.POSLATO ||
          rowData.statusRacunaDto.status === StatusRacuna.NACRT)
      ) ||
      rowData.vezaniRacuniDto.find((x) => x.tip === TipRacuna.PONUDA) != null ||
      (rowData?.vezaniRacuniDto?.length > 0 && rowData?.vezaniRacuniDto?.some(x => x.tip === TipRacuna.AVANSNI_RACUN))
    );
  }

  private _shouldDisplayKonvertujOtpremnicuUFakturu(rowData: IRacunTableDto) {
    return (
      !this._racunService.hasVezaniRacunPoTipuRacuna(
        rowData.vezaniRacuniDto,
        TipRacuna.IZLAZNA_FAKTURA
      ) && rowData.tipRacunaDto.tip == TipRacuna.OTPREMNICA
    );
  }

  private _shouldDisplayKonvertujUOtpremnicu(
    hasVezanRacun: boolean,
    status: StatusRacuna,
    tip: TipRacuna,
    vrsta: VrstaRacuna
  ) {
    return (
      tip === TipRacuna.IZLAZNA_FAKTURA &&
      vrsta !== VrstaRacuna.POCETNO_STANJE_IZLAZNI &&
      (status === StatusRacuna.POSLATO ||
        status === StatusRacuna.NACRT ||
        status === StatusRacuna.PRIHVACENO) &&
      !hasVezanRacun
    );
  }

  private _shouldDisplayKreirajPojedinacnuPdvEvidenciju(
    status: StatusRacuna,
    tip: TipRacuna,
    vrsta: VrstaRacuna
  ) {
    return (
      tip === TipRacuna.IZLAZNA_FAKTURA &&
      (vrsta === VrstaRacuna.ULAZNI || vrsta === VrstaRacuna.IZLAZNI) &&
      (status === StatusRacuna.POSLATO || status === StatusRacuna.PRIHVACENO)
    );
  }

  private _shouldDisplayPonudaToPredracun(
    hasVezanRacun: boolean,
    tip: TipRacuna
  ) {
    return tip === TipRacuna.PONUDA && !hasVezanRacun;
  }

  private _shouldDisplayConvertPredracun(rowData: IRacunTableDto) {
    return (
      rowData.tipRacunaDto.tip === TipRacuna.PREDRACUN &&
      (rowData.statusRacunaDto.status === StatusRacuna.NACRT ||
        rowData.statusRacunaDto.status === StatusRacuna.POSLATO) &&
      (rowData.vezaniRacuniDto?.length === 0 ||
        (rowData.vezaniRacuniDto.length === 1 &&
          rowData.vezaniRacuniDto.findIndex((x) => x.tip === TipRacuna.PONUDA) >
            -1))
    );
  }

  private _shouldDisplayPonistiPredracun(rowData: RacunTableDto) {
    return (
      rowData.tipRacunaDto.tip === TipRacuna.PREDRACUN &&
      rowData.statusUplateDto.status === StatusUplateRacuna.NEPLACEN &&
      rowData.statusRacunaDto.status !== StatusRacuna.PONISTENO
    );
  }

  private _checkIfAvansniHasStornoIf(vezaniRacuni: VezaniRacunDto[]) {
    return (
      vezaniRacuni.filter((x) => x.tip === TipRacuna.IZLAZNA_FAKTURA).length ===
      vezaniRacuni.filter(
        (x) =>
          x.tip === TipRacuna.IZLAZNA_FAKTURA &&
          x.status === StatusRacuna.STORNIRANO
      ).length
    );
  }

  //#endregion Table buttons display logic

  openDialog(
    formType:
      | Type<PosaljiElektronskiFormComponent>
      | Type<StornoIzlazniRacunFormComponent>
      | Type<CancelIzlazniRacunFormComponent>
      | Type<PosaljiNaSefFormComponent>
      | Type<RacunUploadFormComponent>
      | Type<PosaljiIzjavuZaOdbitakPdvFormComponent>
      | Type<RacunExportFormComponent>
      | Type<UplatnicaFormComponent>
  ): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);

    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this._load();
        }
        this.dialogConfig.hideFooter = false;
      })
    );
  }

  private _getRacunRelevantniDokumentListZipped(ufIdArray: number[]) {
    this._subs.add(
      this._client
        .getRacunRelevantniDokumentListZipped(
          new GetRacunRelevantniDokumentListZippedQuery({
            racunIds: ufIdArray,
          })
        )
        .subscribe((res) => {
          const url = window.URL.createObjectURL(res.data);
          window.open(url);
        })
    );
  }

  private _sendRacuniToSef(racuniIds: number[]) {
    this._mixpanelService.izlaznaFakturaSentMultipleToSefSubmitted(
      racuniIds.length
    ),
      this._subs.add(
        this._sefClient
          .sendListToSef(
            new SendRacunListToSefCommand({
              ids: racuniIds,
            })
          )
          .pipe(
            finalize(() => {
              this._loader.reset();
            })
          )
          .subscribe((res) => {
            this._mixpanelService.izlaznaFakturaSentMultipleToSefSucceeded();
            this._alertService.addSuccessMsg(res.data);
            this._load();
          })
      );
  }

  private _isBusy = false;

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters.tip) {
      this.filters.tip = this.tipRacuna;
    }

    if (this.tipRacuna === TipRacuna.EMPTY) {
      this.filters.strankaId = this.strankaId;
    }

    this._subs.add(
      this._client
        .getForTable(this.filters as GetRacunTableQuery)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res: PaginatedResultOfRacunTableDtoAndRacunSaldoDto) => {
          this.racuniTable = res.data;
          this.paginatedData = res;
          this.chipItems = res.activeFilters;
          this._summaryService.setRacunSaldo = res.summaryData;
          this._configService.setIsBusy = false;
          this.tableConfig.advancedFilter.data = this.filters;
        })
    );
  }

  /**
   * Info da li prikazujemo sve tipove racuna ili samo neki odredeni
   */
  get isSingleRacunMode(): boolean {
    return this.tipRacuna !== TipRacuna.EMPTY;
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): IRacunTableDto {
    return this.racuniTable[this._getPaginatedIndex(index)];
  }

  private _updateCurrentPage(): void {
    this._skipFirst = 0;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    // reset filter data
    this._racunFilterService.setAdvanceFilterSubmitted = null;
  }
}
