import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IMailReceiverDto, KontaktiClient } from '@kodit/core/data-api';
import { areArraysEqual, isEmptyCheck } from '@kodit/core/shared';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kodit-email-receiver-autocomplete-multiselect',
  templateUrl: './email-receiver-autocomplete-multiselect.component.html',
  styleUrls: ['./email-receiver-autocomplete-multiselect.component.scss'],
})
export class EmailReceiverAutocompleteMultiselectComponent
  implements OnInit, OnChanges {
  /** Props */
  model: IMailReceiverDto[] = [];
  @ViewChild('controlRef') controlRef: AutoComplete;

  /** I/O */
  @Input() ctrlFormArray: AbstractControl; // FormArray;
  @Input() ctrlId: string;
  @Input() ctrlField: string;
  @Input() ctrlPlaceholder: string = '';
  @Input() forceSelect: boolean = false;
  @Input() suggestions: any[] = [];
  @Output() onSearch = new EventEmitter<string>();
  @Output() onSelect = new EventEmitter<IMailReceiverDto>();
  @Output() onUnselect = new EventEmitter<IMailReceiverDto>();
  @Output() onEnter = new EventEmitter<string>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !isEmptyCheck(changes.suggestions.currentValue) &&
      !areArraysEqual(
        changes.suggestions.currentValue,
        changes.suggestions.previousValue
      )
    ) {
      this.suggestions = changes.suggestions.currentValue;
    }
  }

  ngOnInit(): void {
    this.model = this.ctrlFormArray.value;
    this.ctrlFormArray.valueChanges.subscribe(
      (updatedValues: IMailReceiverDto[]) => {
        if (updatedValues) {
          this.model = updatedValues;
        }
      }
    );
  }

  handleSearch(event: any) {
    // if (isEmailValid(event.query)) { -> necemo ovaj deo koda, jer neko presporo kuca pa se doda nevalidan mail
    //   this.addNewEmail();
    // }
    this.onSearch.emit(event.query);
  }

  handleSelect(item: any) {
    this.onSelect.emit(item);
  }

  handleUnselect(item: any) {
    this.onUnselect.emit(item);
  }

  handleEnter(event: any) {
    if (event.keyCode === 13) {
      this.addNewEmail();
    }
  }

  private addNewEmail() {
    const searchValue = this.controlRef.multiContainerEL.nativeElement.children[
      this.controlRef.multiContainerEL.nativeElement.children.length - 1
    ].firstElementChild.value;

    this.onEnter.emit(searchValue);
    // clear last input value
    if (
      this.controlRef.el.nativeElement?.firstElementChild?.lastElementChild
        ?.lastElementChild?.firstElementChild?.value
    ) {
      this.controlRef.el.nativeElement.firstElementChild.lastElementChild.lastElementChild.firstElementChild.value =
        '';
    }
  }
}
