import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IGetMenuInitResponse,
  InitDataClient,
  PackageType,
  TipPristupa,
} from '@kodit/core/data-api';
import { AuthService, NotificationService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

interface MenuItem {
  label?: string;
  icon?: string;
  routerLink?: string[];
  items?: MenuItem[];
  separator?: boolean;
  isVisible?: boolean;
  notification?: any;
  onClick?: () => void;
}

@Component({
  selector: 'kodit-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  model: any[];
  menuItems: MenuItem[] = [];
  racunStatusNof: number;
  private _subs: Subscription = new Subscription();

  constructor(
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _client: InitDataClient
  ) {}

  ngOnInit() {
    this._loadInit();

    this._subs = this._authService.getUserChanged.subscribe((res) => {
      if (res) {
        this._loadInit();
      }
    });
  }

  // onMenuClick(event: any) {
  //   this.app.onMenuClick(event);
  // }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _menuNotificationsSub() {
    this._subs.add(
      this._notificationService.getRacunStatusChangeNotification.subscribe(
        (res) => {
          this.racunStatusNof = res;
        }
      )
    );
  }

  private _setMenuItems(initData: IGetMenuInitResponse) {
    this.menuItems = [
      // racuni
      {
        label: 'Dokumenti',
        icon: 'fas fa-file-alt',
        isVisible:
          this._authService.hasPackage([
            PackageType[PackageType.FAKTURISANJE],
            PackageType[PackageType.DZABE],
          ]) &&
          this._authService.hasClaim([
            TipPristupa[TipPristupa.IZLAZNA_FAKTURA_VIEW],
            TipPristupa[TipPristupa.AVANSNI_RACUN_VIEW],
            TipPristupa[TipPristupa.IZMENA_OSNOVICE_VIEW],
            TipPristupa[TipPristupa.ULAZNA_FAKTURA_VIEW],
            TipPristupa[TipPristupa.PREDRACUN_VIEW],
            TipPristupa[TipPristupa.PDV_VIEW],
          ]),
        items: [
          {
            label: 'Izlazni računi',
            icon: 'fa-solid fa-file-arrow-up',
            routerLink: ['racuni', 'izlazni-racuni'],
            isVisible:
              this._authService.hasPackage([
                PackageType[PackageType.FAKTURISANJE],
              ]) &&
              this._authService.hasClaim(
                TipPristupa[TipPristupa.IZLAZNA_FAKTURA_VIEW]
              ),
            // notification: () => {
            //   return this.racunStatusNof;
            // },
          },
          {
            label: 'Ulazni računi',
            icon: 'fa-solid fa-file-arrow-down',
            routerLink: ['racuni', 'ulazni-racuni'],
            isVisible:
              (this._authService.hasPackage([
                PackageType[PackageType.FAKTURISANJE],
              ]) ||
                this._authService.hasPackage(PackageType[PackageType.DZABE])) &&
              this._authService.hasClaim(
                TipPristupa[TipPristupa.ULAZNA_FAKTURA_VIEW]
              ),
          },
          {
            label: 'Predračuni',
            icon: 'fa-solid fa-file',
            routerLink: ['racuni', 'predracuni'],
            isVisible:
              this._authService.hasPackage([
                PackageType[PackageType.FAKTURISANJE],
              ]) &&
              this._authService.hasClaim(
                TipPristupa[TipPristupa.PREDRACUN_VIEW]
              ),
          },
          {
            label: 'Ponude',
            icon: 'fa-solid fa-file-contract',
            routerLink: ['racuni', 'ponude'],
            isVisible:
              initData.prikaziPonude &&
              this._authService.hasPackage([
                PackageType[PackageType.FAKTURISANJE],
              ]) &&
              this._authService.hasClaim(
                TipPristupa[TipPristupa.PREDRACUN_VIEW]
              ),
          },
          {
            label: 'Pdv',
            icon: 'fa-solid fa-books',
            routerLink: ['dokumenti', 'pdv-evidencije'],
            isVisible:
              this._authService.hasPackage([
                PackageType[PackageType.FAKTURISANJE],
              ]) &&
              this._authService.hasClaim(TipPristupa[TipPristupa.PDV_VIEW]),
          },
        ],
      },

      // proizvodnja
      {
        label: 'Proizvodnja',
        icon: 'fa-regular fa-conveyor-belt-arm',
        isVisible:
          this._authService.hasPackage([
            PackageType[PackageType.PROIZVODNJA],
          ]) &&
          this._authService.hasClaim(TipPristupa[TipPristupa.RADNI_NALOG_VIEW]),
        items: [
          {
            label: 'Radni nalozi',
            icon: 'fa-solid fa-file-lines',
            routerLink: ['racuni', 'radni-nalozi'],
            isVisible: this._authService.hasClaim(
              TipPristupa[TipPristupa.RADNI_NALOG_VIEW]
            ),
          },
        ],
      },
      // maloprodaja
      {
        label: 'Maloprodaja',
        icon: 'fa-solid fa-money-check',
        isVisible:
          this._authService.hasPackage([
            PackageType[PackageType.MALOPRODAJA],
          ]) &&
          this._authService.hasClaim(
            TipPristupa[TipPristupa.FISKALNI_RACUN_VIEW]
          ),
        items: [
          {
            label: 'Fiskalni računi',
            icon: 'fa-solid fa-cash-register',
            routerLink: ['maloprodaja', 'fiskalni'],
          },
        ],
      },
      // finansije (izvodi)
      {
        label: 'Finansije',
        icon: 'fa-solid fa-money-check',
        isVisible:
          this._authService.hasPackage([PackageType[PackageType.FINANSIJE]]) &&
          this._authService.hasClaim(TipPristupa[TipPristupa.IZVOD_CRUD]),
        items: [
          {
            label: 'Izvodi',
            icon: 'fa-solid fa-money-check',
            routerLink: ['banka', 'izvodi'],
          },
          {
            label: 'Uplate',
            icon: 'fa-solid fa-left-to-line',
            routerLink: ['banka', 'uplate'],
          },
          {
            label: 'Isplate',
            icon: 'fa-solid fa-right-to-line',
            routerLink: ['banka', 'isplate'],
          },
        ],
      },

      // robni proment / magacinsko poslovanje
      {
        label: 'Robni promet',
        isVisible:
          this._authService.hasPackage([
            PackageType[PackageType.VELEPRODAJA],
          ]) &&
          this._authService.hasClaim(
            TipPristupa[TipPristupa.ROBNI_PROMET_VIEW]
          ),
        items: [
          {
            label: 'Otpremnice',
            icon: 'fa-solid fa-file-chart-column',
            routerLink: ['racuni', 'otpremnice'],
            isVisible: this._authService.hasClaim(
              TipPristupa[TipPristupa.OTPREMNICA_VIEW]
            ),
          },
          {
            label: 'Magacini',
            icon: 'fa-solid fa-warehouse',
            routerLink: ['robno/magacini'],
          },
          {
            label: 'Lager',
            icon: 'fa-solid fa-pallet-boxes',
            routerLink: ['robno/lageri'],
          },
          {
            label: 'Kalkulacije',
            icon: 'fa-solid fa-tag',
            routerLink: ['robno/kalkulacije'],
          },
        ],
      },

      // kompanija
      {
        label: 'Kompanija',
        icon: 'fa-solid fa-buildings',
        isVisible: this._authService.hasPackage([
          PackageType[PackageType.MALOPRODAJA],
          PackageType[PackageType.FAKTURISANJE],
          PackageType[PackageType.DZABE],
        ]),
        items: [
          {
            label: 'Pravna lica',
            icon: 'fa-solid fa-buildings',
            routerLink: ['stranke', 'pravna-lica'],
          },
          {
            label: 'Fizička lica',
            icon: 'fa-solid fa-user-group',
            routerLink: ['stranke', 'fizicka-lica'],
            isVisible: this._authService.hasAllOtherPackagesExcept(
              PackageType[PackageType.DZABE]
            ),
          },
          {
            label: 'Vozila',
            icon: 'fa-solid fa-truck',
            routerLink: ['kompanija/vozila'],
            isVisible: this._authService.hasAllOtherPackagesExcept(
              PackageType[PackageType.DZABE]
            ),
          },
          {
            label: 'Cenovnici',
            icon: 'fa-solid fa-coins',
            routerLink: ['kompanija/cenovnici'],
            isVisible: this._authService.hasAllOtherPackagesExcept(
              PackageType[PackageType.DZABE]
            ),
          },
          {
            label: 'Izveštaji',
            icon: 'fa-solid fa-magnifying-glass-chart',
            routerLink: ['kompanija/izvestaji'],
            isVisible:
              this._authService.hasClaim(
                TipPristupa[TipPristupa.IZVESTAJ_VIEW]
              ) &&
              this._authService.hasAllOtherPackagesExcept(
                PackageType[PackageType.DZABE]
              ),
          },
        ],
      },
      //Arhivska knjiga i datoteke
      {
        label: 'DMS',
        isVisible:
          this._authService.hasPackage([
            PackageType[PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK],
          ]) &&
          this._authService.hasClaim([
            TipPristupa[TipPristupa.ARHIVSKA_KNJIGA_VIEW],
          ]),
        items: [
          {
            label: 'Datoteke',
            icon: 'fa-solid fa-folder-open',
            routerLink: ['kompanija/file-manager'],
          },
          {
            label: 'Arhivska knjiga',
            icon: 'fas fa-light fa-folder-tree',
            routerLink: ['kompanija/arhivska-knjiga'],
          },
          {
            label: 'Delovodnik',
            icon: 'fa-regular fa-table',
            routerLink: ['kompanija/delovodnik'],
          },
        ],
      },
      {
        label: '',
        icon: 'fas fa-users-cog',
        items: [
          {
            label: 'Tehnička podrška',
            icon: 'fa-solid fa-headset',
            routerLink: ['tehnicka-podrska'],
          },
        ],
      },
    ];
  }

  private _loadInit() {
    this._subs.add(
      this._client.getMenuInit().subscribe((res) => {
        this._setMenuItems(res.data);
      })
    );
  }
}
