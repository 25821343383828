<p-tabView
  (activeIndexChange)="handleIndexChange($event)"
  class="kodit-card-tab"
>
  <p-tabPanel #bezbednosniElementiPanel header="Sertifikati"></p-tabPanel>
  <p-tabPanel #poreskeStopePanel header="Poreske stope"></p-tabPanel>
</p-tabView>

<div [ngSwitch]="activeIndex">
  <div *ngSwitchCase="0">
    <kodit-bezbednosni-elementi-table>
    </kodit-bezbednosni-elementi-table>
  </div>

  <div *ngSwitchCase="1">
    <kodit-page>
      <kodit-poreske-stop-fiskalne-kase>
      </kodit-poreske-stop-fiskalne-kase>
    </kodit-page>
  </div>
</div>
