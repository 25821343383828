<ng-container [ngSwitch]="column.type">
  <!-- BADGE -->
  <ng-container *ngSwitchCase="'badge'">
    <ng-container
      *ngIf="column.subField; then badgeIsObject; else badgeIsSimple"
    >
    </ng-container>
  </ng-container>

  <!-- LINK -->
  <ng-container *ngSwitchCase="'link'">
    <ng-container *ngIf="column.subField; then linkIsObject; else linkIsSimple">
    </ng-container>
  </ng-container>

  <!-- NUMBER -->
  <ng-container *ngSwitchCase="'number'">
    <ng-container
      *ngIf="column.subField; then numberIsObject; else numberIsSimple"
    >
    </ng-container>
  </ng-container>

  <!-- BOOLEAN -->
  <ng-container *ngSwitchCase="'bool'">
    <ng-container *ngIf="column.subField; then boolIsObject; else boolIsSimple">
    </ng-container>
  </ng-container>

  <!-- TEXT -->
  <ng-container *ngSwitchCase="'text'">
    <ng-container *ngIf="column.subField; then textIsObject; else textIsSimple">
    </ng-container>
  </ng-container>

  <!-- CURRENCY -->
  <ng-container *ngSwitchCase="'currency'">
    <ng-container
      *ngIf="column.subField; then currencyIsObject; else currencyIsSimple"
    >
    </ng-container>
  </ng-container>

  <!-- LIST -->
  <ng-container *ngSwitchCase="'list'">
    <ng-container *ngIf="column.subField; then listIsObject; else listIsSimple">
    </ng-container>
  </ng-container>

  <!-- INITIALS -->
  <ng-container *ngSwitchCase="'initials'">
    <ng-container
      *ngIf="column.subField; then initialsIsObject; else initialsIsSimple"
    >
    </ng-container>
  </ng-container>

  <!-- ICON -->
  <ng-container *ngSwitchCase="'icon'">
    <ng-container *ngTemplateOutlet="iconIsObject"></ng-container>
  </ng-container>

  <!-- TODO: Prebaciti sve templates u novi fajl i onda ga iz njega citati -->
  <!-- TEMPLATES -->

  <ng-template #badgeIsObject>

    <span
      [ngClass]="
        'item-badge ' +
        column.styleClass +
        '-' +
        item[column.field] [column.styleClassField]
      "
      ><i class="circle-icon pi pi-circle-on" style="font-size: 6px;"></i>{{

        item[column.field] === null ? '--' : item[column.field][column.subField]
      }}</span
    >
    <div
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <div
        class="p-pr-2 p-label p-text"
        *ngIf="item[col2.field].statusBadgeStr !== 'empty'"
      >
        {{ col2.header }}:
        <span
          [ngClass]="
            'item-badge ' +
            col2.styleClass +
            '-' +
            item[col2.field][col2.styleClassField].toLowerCase()
          "
        >
          {{ item[col2.field][col2.subField] }}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #badgeIsSimple>
    <span
      *ngIf="item[column.field]"
      [ngClass]="
        'item-badge ' +
        column.styleClass +
        '-' +
        item[column.field].toLowerCase()
      "
    >
      {{ item[column.field] }}
    </span>

    <div
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        *ngIf="item[col2.field]"
        [ngClass]="
          'item-badge ' +
          column.styleClass +
          '-' +
          item[col2.field].toLowerCase()
        "
      >
        {{ item[col2.field] }}
      </span>
    </div>
  </ng-template>

  <ng-template #linkIsSimple>
    <div *ngIf="item[column.field]">
      <button
        pButton
        type="button"
        [label]="item[column.field]"
        style="text-align: initial"
        class="p-text-left p-button-link p-p-0 text-underline"
        (click)="column.linkCallbackFunction(rowIndex)"
      ></button>
    </div>
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span *ngIf="!col2.subField" class="p-pr-2 p-label"
        >{{ col2.header }}:
        <span class="p-text">{{ item[col2.field] }}</span>
      </span>
      <!-- ako ima vise vezanih racuna -->
      <span
        *ngIf="
          col2.subField &&
          col2.type === 'link' &&
          item[col2.field] &&
          item[col2.field]?.length > 0
        "
      >
        <ng-container [ngSwitch]="item[col2.field] | typeof">
          <ng-container *ngSwitchCase="objectIsArray">
            <div class="p-d-inline-flex">
              <span *ngIf="item[col2.field].length > 0"
                >{{ col2.header }}:
              </span>
              <div class="p-d-flex p-flex-column">
                <div
                  *ngFor="
                    let el of item[col2.field] | slice: 0:9;
                    let i = index
                  "
                >
                  <button
                    *ngIf="el[col2.subField]"
                    pButton
                    type="button"
                    [label]="el[col2.subField]"
                    class="p-button-link p-p-0 text-underline"
                    (click)="col2.linkCallbackFunction(rowIndex, i)"
                  ></button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="objectIsString">
            u pitanju je string
          </ng-container>
        </ng-container>
      </span>

      <!-- ako je 1 objekat a jeste link -->
      <span *ngIf="item[col2.subField] && col2.type !== 'link'">
        {{ col2.header }}:
        <span class="p-text">{{ item[col2.field][col2.subField] }}</span>
      </span>

      <!-- ako je 1 objekat a nije link   -->
      <span *ngIf="col2.subField && col2.type === 'link' && (item[col2.field]?.length == undefined)">
        <span *ngIf="col2.header">{{ col2.header }}:</span>
        <button
          *ngIf="item[col2.field][col2.subField]"
          pButton
          type="button"
          [label]="item[col2.field][col2.subField]"
          class="p-button-link p-p-0 text-underline"
          (click)="col2.linkCallbackFunction(rowIndex, i)"
        ></button>
      </span>

      <span class="p-pr-2" *ngIf="column.columns.length != i + 1">|</span>
    </span>
    <span *ngIf="!item[column.field]">--</span>
  </ng-template>

  <ng-template #linkIsObject>
    <div>
      <button
        *ngIf="item[column.field] && item[column.field][column.subField]"
        pButton
        type="button"
        [label]="item[column.field][column.subField]"
        class="p-text-left p-button-link p-p-0 text-underline"
        (click)="column.linkCallbackFunction(rowIndex)"
      ></button>
      <span
        *ngIf="item[column.field] && item[column.field][column.emptyCellField]"
        >{{ item[column.field][column.emptyCellField] }}</span
      >
    </div>
    <div>
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          *ngIf="item[col2.field] && item[col2.field][col2.subField]"
          class="p-text-nowrap p-text-truncate"
          [ngClass]="
            'p-text-nowrap p-text-truncate ' +
            col2.styleClass +
            '-' +
            item[col2.field][col2.styleClassField]
          "
        >
          {{ item[col2.field][col2.subField] }}
        </span>
        <span class="p-pr-2" *ngIf="column.columns.length != i + 1">|</span>
      </span>
    </div>
    <span *ngIf="!item[column.field]">--</span>
  </ng-template>

  <ng-template #numberIsSimple>
    <div style="text-align: end">
      <span class="p-text-nowrap p-text-truncate">
        {{ item[column.field] }}
      </span>
    </div>

    <div style="text-align: end">
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
          class="p-label"
          >{{ col2.header }}
          <span *ngIf="col2.header != ''">:</span>
          <span class="p-text">{{ item[col2.field] }}</span>
        </span>
        <span
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
          *ngIf="column.columns.length != i + 1"
          >|</span
        >
      </span>
    </div>
  </ng-template>

  <ng-template #numberIsObject>
    <span>#TODO: add numberIsObject template</span>
  </ng-template>

  <ng-template #textIsSimple>
    <div>
      <span
        *ngIf="item[column.field]"
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column.styleClassField]
        "
      >
        {{ item[column.field] }}
      </span>
      <span *ngIf="!item[column.field]">--</span>
    </div>

    <!-- <div>
    <span
      *ngFor="let col2 of column.columns; let i = index"
      [attr.data-index]="i"
    >
      <span
        *ngIf="col2.header"
        [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        class="p-label"
        >{{ col2.header + ': ' }}
      </span>
      <span
        [ngClass]="
          col2.styleClass + '-' + item[col2.field][col2.styleClassField]
        "
        >{{ item[col2.field] }}</span
      >
      <span
        [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
        *ngIf="column.columns.length != i + 1"
        >|</span
      >
    </span>
  </div> -->
    <div>
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span class="p-pr-2 p-label"
          >{{ col2.header }}:
          <span class="p-text">{{ item[col2.field] }}</span>
        </span>
        <span class="p-pr-2" *ngIf="column.columns.length != i + 1">|</span>
      </span>
    </div>
  </ng-template>

  <ng-template #textIsObject>
    <div>
      <span
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column.styleClassField]
        "
      >
        {{ item[column.field][column.subField] }}
      </span>
    </div>
    <div *ngIf="column.columns && column.columns.length > 0">
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        {{ item[col2.field][col2.subField] }}
      </span>
      <span class="p-pr-2" *ngIf="column.columns.length != i + 1">| </span>
    </div>
  </ng-template>

  <ng-template #currencyIsSimple>
    <div style="text-align: end">
      <kodit-helper-icon
        *ngIf="column.helperText"
        [helperText]="column.helperText"
      ></kodit-helper-icon>
      <span
        *ngIf="column.useColorsForCurrency"
        kodit-currency-directive
        [currencyValue]="item[column.field]"
        >{{
          item[column.field]
            | currencyNumber: item[column.currencyAlphaCharField]
        }}</span
      >
      <span *ngIf="!column.useColorsForCurrency">{{
      (item[column.field] ?? 0) | currencyNumber: item[column.currencyAlphaCharField]
      }}</span>
    </div>
    <!-- multi-row -->
    <div *ngIf="column.columns" style="text-align: end">
      <span
        *ngFor="let col2 of column.columns; let i = index"
        [attr.data-index]="i"
      >
        <span
          *ngIf="col2.shouldDisplayByCondition(item[col2.field])"
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
          >{{ col2.header }}
          <span *ngIf="col2.header">:</span>
          <kodit-helper-icon
            *ngIf="col2.helperText"
            [helperText]="col2.helperText"
          ></kodit-helper-icon>
          <span
            *ngIf="col2.useColorsForCurrency"
            kodit-currency-directive
            [currencyValue]="item[col2.field]"
            >{{
              item[col2.field]
                | currencyNumber: item[column.currencyAlphaCharField]
            }}</span
          >
          <span *ngIf="!col2.useColorsForCurrency">{{
            item[col2.field] | currencyNumber: item[col2.currencyAlphaCharField]
          }}</span>
        </span>
        <span
          [ngClass]="{ 'p-pr-2': column.columns.length != i + 1 }"
          *ngIf="column.columns.length != i + 1"
          >|</span
        >
      </span>
    </div>
  </ng-template>

  <ng-template #currencyIsObject>
    <span>#TODO: add currencyIsObject template</span>
  </ng-template>

  <ng-template #listIsSimple>
    <div class="p-text" *ngFor="let el of item[column.field] | slice: 0:9">
      <div class="p-text-nowrap p-text-truncate" [pTooltip]="el.label">
        - {{ el.value }}
      </div>
    </div>
    <div *ngIf="item[column.field].length > 9">...</div>
  </ng-template>

  <ng-template #listIsObject>
    <div
      class="p-text"
      *ngFor="let el of item[column.field] | slice: 0:9; let i = index"
    >
      <button
        *ngIf="el[column.subField]"
        pButton
        type="button"
        [label]="el[column.subField]"
        class="p-text-left p-button-link p-p-0 text-underline"
        (click)="column.linkCallbackFunction(rowIndex, i)"
      ></button>
      <span *ngIf="el[column.emptyCellField]">{{
        el[column.emptyCellField]
      }}</span>
    </div>
    <div *ngIf="item[column.field].length > 9">...</div>
    <div *ngIf="item[column.field].length == 0">--</div>
  </ng-template>

  <ng-template #initialsIsSimple>
    <div class="profile-image row-initials" width="50" height="50">
      {{ item[column.field][0] }}
    </div>
  </ng-template>

  <ng-template #initialsIsObject>
    <span>ToDo: initialsIsObject</span>
  </ng-template>

  <ng-template #iconIsObject>
    <div role="menuitem">
      <span class="fa-stack k-fa-3x">
        <i
          class="_icon_back fas fa-square fa-stack-2x"
          [ngStyle]="{ color: item[column.field].backgroundColor }"
        ></i>
        <i
          [ngClass]="'_icon_front fa-stack-1x ' + item[column.field].iconName"
          [ngStyle]="{ color: item[column.field].iconColor }"
        ></i>
      </span>
    </div>
  </ng-template>

  <ng-template #boolIsSimple>
    <div>
      <span
        *ngIf="item[column.field]"
        [ngClass]="
          'p-text-nowrap p-text-truncate ' +
          column.styleClass +
          '-' +
          item[column.field][column.styleClassField]
        "
      >
        Da
      </span>
      <span *ngIf="!item[column.field]">Ne</span>
    </div>
  </ng-template>

  <ng-template #boolIsObject>
    <span>#TODO: add boolIsObject template</span>
  </ng-template>
</ng-container>
