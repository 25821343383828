import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  MojaFirmaDto,
  PoslovnaJedinicaTableDto,
  PoslovneJediniceClient,
  ResultOfPoslovnaJedinicaTableDto,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { PoslovnaJedinicaFormComponent } from '../poslovna-jedinica-form/poslovna-jedinica-form.component';

@Component({
  selector: 'kodit-poslovna-jedinica-table',
  templateUrl: './poslovna-jedinica-table.component.html',
  styleUrls: ['./poslovna-jedinica-table.component.scss'],
})
export class PoslovnaJedinicaTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Subscriptions */
  private _subs: Subscription = new Subscription();

  private _deleteSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  mojaFirma: MojaFirmaDto;

  /** Props */
  jeAktivnaStr: string;

  poslovneJedinicaTable: PoslovnaJedinicaTableDto[] = [];
  @Input() showCardBlock: boolean = false;

  constructor(
    private _client: PoslovneJediniceClient,
    private _dialogService: DynamicDialogService,
    private _alertService: AlertService,
    private _route: ActivatedRoute
  ) {}

  /** OnInit */
  ngOnInit(): void {
    this.mojaFirma = this._route.snapshot.data.mojaFirma;
    this._load();
    this.setTableConfig();
  }

  //#region  OnTableInit
  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Poslovne jedinice',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete poslovnu jedinicu {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete selektovane poslovne jedinice?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'text',
        },
        {
          field: 'punaAdresa',
          header: 'Adresa',
          type: 'text',
        },
        {
          field: 'vrsta',
          subField: 'opis',
          header: 'Vrsta',
          styleClass: 'vrsta-poslovne-jedinice',
          styleClassField: 'vrstaPoslovneJediniceBadgeStr',
          type: 'badge',
        },
        {
          field: 'jeAktivna',
          header: 'Aktivna',
          type: 'badge',
          styleClass: 'jeAktivna',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite poslovne jedinice',
          noAccessTooltip: 'Nemate ovlašćenja za unos poslovne jedinice',
          callback: () => {
            this.dialogConfig.data = { id: this.mojaFirma.id, isEdit: false };
            this.dialogConfig.header = 'Unos podataka poslovne jedinice';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite poslovnu jedinicu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu poslovne jedinice',
          callback: (index: number) => {
            this.dialogConfig.data = {
              id: this.poslovneJedinicaTable[index]?.id,
              isEdit: true,
            };
            this.dialogConfig.header = 'Izmena podataka poslovne jedinice';
            this.openDialog(this.dialogConfig);
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.BRISANJE_POSLOVNE_JEDINICE,
        //   hasAccessTooltip: 'Obrišite poslovnu jedinicu',
        //   noAccessTooltip: 'Nemate ovlašćenja za brisanje poslovne jedinice',
        //   callback: (index: number) => {
        //    const forDelete = this.poslovneJedinicaTable[itemIndex];
        // this._deleteSub = this._client.delete(forDelete.id).subscribe(
        //   () => {
        //     this.poslovneJedinicaTable.splice(itemIndex, 1);
        //     this._alertService.addSuccessMsg(
        //       `Vozilo ${forDelete.sifraINaziv} je uspešno obrisano.`
        //     );
        //   },
        //   (error) => {
        //     this._alertService.addFailedMsg(error);
        //   }
        // );
        //   },
        // },
      ],
    });
  }

  //#endregion OnTableInit

  /** OnDynamicDialogInit */
  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(PoslovnaJedinicaFormComponent, config);

    this._dialogCloseSub = ref.onClose.subscribe(() => {
      this._load();
    });
  }

  private _load() {
    this._subs.add(
      this._client.getForTable().subscribe(
        (res) => {
          this.poslovneJedinicaTable = res.data.responseList;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  /** OnDestroy */
  ngOnDestroy(): void {
    this._deleteSub.unsubscribe();
    this._subs.unsubscribe();
    this._dialogCloseSub.unsubscribe();
  }
}
