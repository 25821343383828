import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AlertService, ButtonService, SharedService} from "@kodit/core/services";
import {DynamicDialogRef, FileUploadService} from "@kodit/core/shared-ui";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FileParameter, FiskalniRacuniClient, IPoslovnaJedinicaDto, XmlRacuna} from "@kodit/core/data-api";
import {FormHelper} from "@kodit/core/form-definitions";

@Component({
  selector: 'kodit-sertifikat-fiskalna-upload-form',
  templateUrl: './sertifikat-fiskalna-upload-form.component.html',
  styleUrls: ['./sertifikat-fiskalna-upload-form.component.scss'],
})
export class SertifikatFiskalnaUploadFormComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  readonly MAX_FILE_SIZE: number = 10000000;

  /** Props */
  uploadCompleted: boolean;
  file: FileParameter | undefined;
  poslovnaJedinicaId!: number;
  imaMagacina: boolean = true;
  nazivMagacina: string = '';

  /** Form */
  form: FormGroup;

  constructor(
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilder,
    private _formHelper: FormHelper,
    private _client: FiskalniRacuniClient,
    private _uploadService: FileUploadService,
    private _sharedService: SharedService,
    private _buttonService: ButtonService
  ) {}

  ngOnInit(): void {
    this._initForm();
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  handleUpload(file: File) {
    this.file = { fileName: file.name, data: file };
  }

  private save() {
    debugger;
    if (!this.form.valid || !this.poslovnaJedinicaId) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    const command = this.form.value;

    // everything is okay we save it
    this._sharedService.displayLoadingScreen(
      true,
      'Učitavanje sertifikata u toku...'
    );
    this._subs.add(
      this._client
        .importSertifikatFiskalneKase(
          command.naziv,
          command.jid,
          command.lozinka,
          command.pak,
          this.poslovnaJedinicaId,
          this.file
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg('Uspešno ste uvezli sertifikat!');
          } else {
            this._alertService.addFailedMsg('Sertifikat nije uvezen!');
          }
          this._dialogRef.close(result);
        })
    );
  }

  private _initForm() {
    this.form = this._fb.group({
      id: new FormControl(0, {
        validators: [Validators.nullValidator],
      }),
      naziv: new FormControl('', {
        validators: [Validators.required],
      }),
      jid: new FormControl('', {
        validators: [Validators.required],
      }),
      lozinka: new FormControl('', {
        validators: [Validators.required],
      }),
      pak: new FormControl('', {
        validators: [Validators.required],
      }),
    });
  }

  handlePoslovnaJedinicaChanged(poslovnaJedinica: IPoslovnaJedinicaDto) {
    this.poslovnaJedinicaId = poslovnaJedinica.id!;
    if (poslovnaJedinica.magacini?.length === 0) {
      this.imaMagacina = false;
      this._buttonService.disableButton();
      return;
    }

    poslovnaJedinica.magacini?.forEach((x) => {
      this.nazivMagacina += x.naziv + ',';
    });

    this.imaMagacina = true;
    this._buttonService.enableButton();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

