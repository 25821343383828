import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TehnickaPodrskaPageComponent } from './tehnicka-podrska-page/tehnicka-podrska-page.component';
import { CoreSharedModule } from '@kodit/core/shared';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';
import { TehnickaPodrskaRoutingModule } from './moduli-tehnicka-podrska-routing.module';

@NgModule({
  imports: [
    CommonModule,
    CoreSharedModule,
    CoreSharedUiModule,
    TehnickaPodrskaRoutingModule,
  ],
  declarations: [TehnickaPodrskaPageComponent],
})
export class CoreModuliTehnickaPodrskaModule {}
